import React from 'react';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import toast from 'react-hot-toast';

interface FormData {
  serviceType: string;
  name: string;
  email: string;
  phone: string;
  fromAddress: string;
  toAddress: string;
  size: string;
  description: string;
  moveDate: string;
}

export const ServiceRequestForm = () => {
  const { register, handleSubmit, formState: { errors, isSubmitting }, reset } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    try {
      console.log('Sending data:', data);
      
      const response = await fetch('https://hooks.zapier.com/hooks/catch/20475824/2iqubou/', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          timestamp: new Date().toISOString()
        }),
      });

      toast.success('Tack för din förfrågan! Vi återkommer inom kort.');
      reset();
    } catch (error) {
      console.error('Detailed form submission error:', error);
      toast.error('Ett fel uppstod. Försök igen senare.');
    }
  };

  // Get tomorrow's date in YYYY-MM-DD format for min date
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const minDate = tomorrow.toISOString().split('T')[0];

  return (
    <section id="service-request-form" className="py-24 bg-gradient-to-b from-white to-coral-50/10">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-playfair font-bold mb-4">Få kostnadsfria offerter</h2>
          <p className="text-gray-600">Fyll i formuläret så hjälper vi dig hitta rätt företag</p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Typ av tjänst <span className="text-red-500">*</span>
              </label>
              <select
                {...register('serviceType', { required: "Välj en tjänst" })}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
              >
                <option value="">Välj tjänst</option>
                <option value="flytthjalp">Flytthjälp</option>
                <option value="kontorsflytt">Kontorsflytt</option>
                <option value="utlandsflytt">Utlandsflytt</option>
                <option value="flyttstadning">Flyttstädning</option>
                <option value="magasinering">Magasinering</option>
                <option value="dodsbo">Dödsbo</option>
              </select>
              {errors.serviceType && (
                <p className="mt-1 text-sm text-red-600">{errors.serviceType.message}</p>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Namn <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  {...register('name', { required: "Namn är obligatoriskt" })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                  placeholder="För- och efternamn"
                />
                {errors.name && (
                  <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Telefon <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  {...register('phone', { required: "Telefonnummer är obligatoriskt" })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                  placeholder="070 123 45 67"
                />
                {errors.phone && (
                  <p className="mt-1 text-sm text-red-600">{errors.phone.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                E-post <span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                {...register('email', { 
                  required: "E-post är obligatoriskt",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Ogiltig e-postadress"
                  }
                })}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                placeholder="namn@exempel.se"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Från adress <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  {...register('fromAddress', { required: "Från-adress är obligatoriskt" })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                  placeholder="Gatuadress"
                />
                {errors.fromAddress && (
                  <p className="mt-1 text-sm text-red-600">{errors.fromAddress.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Till adress <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  {...register('toAddress', { required: "Till-adress är obligatoriskt" })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                  placeholder="Gatuadress"
                />
                {errors.toAddress && (
                  <p className="mt-1 text-sm text-red-600">{errors.toAddress.message}</p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Önskat datum <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                {...register('moveDate', { required: "Datum är obligatoriskt" })}
                min={minDate}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
              />
              {errors.moveDate && (
                <p className="mt-1 text-sm text-red-600">{errors.moveDate.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Storlek på bostad/lokal (kvm) <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                {...register('size', { 
                  required: "Storlek är obligatoriskt",
                  min: { value: 1, message: "Storlek måste vara större än 0" }
                })}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                placeholder="120"
              />
              {errors.size && (
                <p className="mt-1 text-sm text-red-600">{errors.size.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Beskriv vad du vill ha hjälp med <span className="text-red-500">*</span>
              </label>
              <textarea
                {...register('description', { 
                  required: "Beskrivning är obligatoriskt",
                  minLength: { value: 10, message: "Beskriv med minst 10 tecken" }
                })}
                rows={4}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                placeholder="Ju mer utförligt du beskriver dina behov, desto bättre kan vi matcha dig med rätt företag"
              />
              {errors.description && (
                <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
              )}
            </div>
          </div>

          <motion.button
            type="submit"
            disabled={isSubmitting}
            whileHover={{ scale: isSubmitting ? 1 : 1.02 }}
            whileTap={{ scale: isSubmitting ? 1 : 0.98 }}
            className={`w-full btn-primary group flex items-center justify-center space-x-2 ${
              isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <span>{isSubmitting ? 'Skickar...' : 'Skicka förfrågan'}</span>
            <ArrowRight className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
          </motion.button>
        </form>
      </div>
    </section>
  );
};