import React from 'react';
import { SEO } from '../components/shared/SEO';
import { Globe, Shield, FileCheck } from 'lucide-react';
import { PageHero } from '../components/shared/PageHero';
import { RecoWidget } from '../components/ui/RecoWidget';
import { InternationalMoveFeatures } from '../components/international-move/InternationalMoveFeatures';
import { InternationalMoveProcess } from '../components/international-move/InternationalMoveProcess';
import { InternationalMoveOptions } from '../components/international-move/InternationalMoveOptions';
import { InternationalMovePackingGuide } from '../components/international-move/InternationalMovePackingGuide';
import { InternationalMoveWhyUs } from '../components/international-move/InternationalMoveWhyUs';
import { InternationalMoveGuarantees } from '../components/international-move/InternationalMoveGuarantees';
import { ServiceGrid } from '../components/shared/ServiceGrid';
import { ServiceRequestForm } from '../components/shared/ServiceRequestForm';

export const InternationalMoveHelp = () => {
  const stats = [
    { icon: Globe, text: "Global expertis" },
    { icon: Shield, text: "Fullständigt försäkringsskydd" },
    { icon: FileCheck, text: "Dokumenthantering" }
  ];

  const handleCtaClick = () => {
    const formElement = document.getElementById('service-request-form');
    if (formElement) {
      const headerOffset = 100;
      const elementPosition = formElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Utlandsflytt - Internationell flytthjälp"
        description="Specialister på internationella flyttar. Vi hjälper dig med dokumentation, transport och logistik för en trygg utlandsflytt. Jämför offerter från erfarna flyttfirmor."
      />
      <PageHero
        title="Professionell"
        titleHighlight="utlandsflytt"
        description="Vi hjälper dig med dokumentation, transport och logistik för en trygg utlandsflytt. Jämför offerter från erfarna flyttfirmor."
        stats={stats}
        ctaText="Få offerter nu"
        ctaAction={handleCtaClick}
      />
      <RecoWidget />
      <InternationalMoveFeatures />
      <InternationalMoveProcess />
      <ServiceRequestForm />
      <InternationalMoveOptions />
      <InternationalMovePackingGuide />
      <InternationalMoveWhyUs />
      <InternationalMoveGuarantees />
      <ServiceGrid />
    </div>
  );
};