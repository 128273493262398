import React from 'react';
import { motion } from 'framer-motion';
import { Shield, CheckCircle, Users } from 'lucide-react';

const trustFeatures = [
  {
    icon: Shield,
    title: 'Kvalitetssäkrade partners',
    description: 'Alla våra partners genomgår noggrann granskning'
  },
  {
    icon: CheckCircle,
    title: 'Verifierade flyttfirmor',
    description: 'Vi samarbetar endast med licensierade och försäkrade företag'
  },
  {
    icon: Users,
    title: 'Personlig service',
    description: 'Vi matchar dig med flyttfirmor som passar dina specifika behov'
  }
];

export const MovingTrust = () => {
  return (
    <section className="py-32 bg-gradient-to-b from-white to-coral-50/10 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h2 className="text-4xl md:text-5xl font-playfair font-bold mb-6">
            Känn dig trygg med din{' '}
            <span className="text-coral-500">flytt</span>
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            Din trygghet är vår högsta prioritet. Vi samarbetar endast med seriösa och noggrant 
            utvalda flyttfirmor.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {trustFeatures.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: [0.21, 0.45, 0.27, 0.9]
              }}
              className="group relative"
            >
              <div className="relative bg-white rounded-2xl p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500">
                <div className="relative mb-6">
                  <div className="w-16 h-16 bg-coral-50 rounded-xl flex items-center justify-center group-hover:bg-coral-100 transition-transform duration-500 group-hover:scale-110">
                    <feature.icon className="w-8 h-8 text-coral-500 transform group-hover:scale-110 transition-transform duration-500" />
                    <div className="absolute inset-0 bg-coral-200/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                  </div>
                </div>

                <h3 className="text-xl font-semibold mb-3 group-hover:text-coral-500 transition-colors duration-300">
                  {feature.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {feature.description}
                </p>

                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};