import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Building2, MapPin, Phone, Mail, Globe, Shield, FileText, CheckCircle, ArrowLeft } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { Company, db } from '../../lib/supabase';

export const EditCompany = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const { register, handleSubmit, formState: { errors, isSubmitting }, reset } = useForm<Partial<Company>>();

  React.useEffect(() => {
    if (id) {
      fetchCompany(id);
    }
  }, [id]);

  const fetchCompany = async (id: string) => {
    try {
      const { data, error } = await db.companies.getById(id);
      if (error) throw error;
      reset(data);
    } catch (error) {
      console.error('Error fetching company:', error);
      navigate('/admin/companies');
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data: Partial<Company>) => {
    if (!id) return;
    try {
      await db.companies.update(id, data);
      navigate('/admin/companies');
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };

  const services = [
    'Flytthjälp',
    'Kontorsflytt',
    'Utlandsflytt',
    'Flyttstädning',
    'Magasinering',
    'Dödsbo'
  ];

  const certifications = [
    'ISO 14001',
    'ISO 9001'
  ];

  const FormField = ({ 
    label, 
    error, 
    children, 
    icon: Icon,
    description 
  }: { 
    label: string; 
    error?: string; 
    children: React.ReactNode;
    icon?: React.ElementType;
    description?: string;
  }) => (
    <div className="space-y-1">
      <label className="block text-sm font-medium text-gray-700">
        <div className="flex items-center gap-2 mb-1">
          {Icon && <Icon className="w-4 h-4 text-gray-400" />}
          {label}
        </div>
      </label>
      {description && (
        <p className="text-sm text-gray-500 mb-2">{description}</p>
      )}
      {children}
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-coral-500" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigate('/admin/companies')}
            className="text-gray-600 hover:text-gray-900 transition-colors"
          >
            <ArrowLeft className="w-6 h-6" />
          </button>
          <h1 className="text-2xl font-semibold text-gray-900">
            Redigera företag
          </h1>
        </div>
      </div>

      <div className="bg-white rounded-xl shadow-sm">
        <form onSubmit={handleSubmit(onSubmit)} className="p-8 space-y-8">
          {/* Basic Information */}
          <div className="space-y-6">
            <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2">
              <Building2 className="w-5 h-5 text-coral-500" />
              Grundinformation
            </h3>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FormField 
                label="Företagsnamn" 
                error={errors.name?.message}
                icon={Building2}
              >
                <input
                  type="text"
                  {...register('name', { required: 'Detta fält är obligatoriskt' })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-coral-500 focus:border-transparent transition-shadow"
                  placeholder="Företagets namn"
                />
              </FormField>

              <FormField 
                label="Stad" 
                error={errors.city?.message}
                icon={MapPin}
              >
                <input
                  type="text"
                  {...register('city', { required: 'Detta fält är obligatoriskt' })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-coral-500 focus:border-transparent transition-shadow"
                  placeholder="Stad"
                />
              </FormField>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FormField 
                label="Telefon"
                icon={Phone}
              >
                <input
                  type="tel"
                  {...register('phone')}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-coral-500 focus:border-transparent transition-shadow"
                  placeholder="070-123 45 67"
                />
              </FormField>

              <FormField 
                label="E-post"
                icon={Mail}
              >
                <input
                  type="email"
                  {...register('email')}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-coral-500 focus:border-transparent transition-shadow"
                  placeholder="info@foretag.se"
                />
              </FormField>
            </div>

            <FormField 
              label="Hemsida"
              icon={Globe}
            >
              <input
                type="url"
                {...register('website')}
                className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-coral-500 focus:border-transparent transition-shadow"
                placeholder="https://www.foretag.se"
              />
            </FormField>
          </div>

          {/* Services */}
          <div className="space-y-6">
            <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2">
              <CheckCircle className="w-5 h-5 text-coral-500" />
              Tjänster och certifieringar
            </h3>

            <FormField 
              label="Tjänster"
              description="Välj de tjänster som företaget erbjuder"
            >
              <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                {services.map((service) => (
                  <label 
                    key={service} 
                    className="relative flex items-center p-4 rounded-lg border border-gray-200 cursor-pointer hover:bg-gray-50 transition-colors"
                  >
                    <input
                      type="checkbox"
                      {...register('services')}
                      value={service}
                      className="h-4 w-4 text-coral-500 border-gray-300 rounded focus:ring-coral-500"
                    />
                    <span className="ml-3 text-sm text-gray-700">{service}</span>
                  </label>
                ))}
              </div>
            </FormField>

            <FormField 
              label="Certifieringar"
              description="Välj företagets certifieringar"
            >
              <div className="grid grid-cols-2 gap-3">
                {certifications.map((cert) => (
                  <label 
                    key={cert} 
                    className="relative flex items-center p-4 rounded-lg border border-gray-200 cursor-pointer hover:bg-gray-50 transition-colors"
                  >
                    <input
                      type="checkbox"
                      {...register('certifications')}
                      value={cert}
                      className="h-4 w-4 text-coral-500 border-gray-300 rounded focus:ring-coral-500"
                    />
                    <span className="ml-3 text-sm text-gray-700">{cert}</span>
                  </label>
                ))}
              </div>
            </FormField>
          </div>

          {/* Description and Notes */}
          <div className="space-y-6">
            <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2">
              <FileText className="w-5 h-5 text-coral-500" />
              Beskrivning och anteckningar
            </h3>

            <FormField 
              label="Beskrivning"
              description="En kort beskrivning av företaget som visas publikt"
            >
              <textarea
                {...register('description')}
                rows={4}
                className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-coral-500 focus:border-transparent transition-shadow"
                placeholder="Beskriv företaget..."
              />
            </FormField>

            <FormField 
              label="Interna anteckningar"
              description="Anteckningar som endast visas i admin-panelen"
            >
              <textarea
                {...register('notes')}
                rows={4}
                className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-coral-500 focus:border-transparent transition-shadow"
                placeholder="Interna anteckningar..."
              />
            </FormField>

            <FormField 
              label="Verifiering"
              description="Markera företaget som verifierat när alla kontroller är genomförda"
            >
              <label className="relative flex items-center p-4 rounded-lg border border-gray-200 cursor-pointer hover:bg-gray-50 transition-colors">
                <input
                  type="checkbox"
                  {...register('is_verified')}
                  className="h-4 w-4 text-coral-500 border-gray-300 rounded focus:ring-coral-500"
                />
                <span className="ml-3 text-sm text-gray-700">
                  Verifiera företaget
                </span>
              </label>
            </FormField>
          </div>

          {/* Submit Buttons */}
          <div className="flex justify-end gap-3 pt-6 border-t">
            <button
              type="button"
              onClick={() => navigate('/admin/companies')}
              className="px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors"
            >
              Avbryt
            </button>
            <motion.button
              type="submit"
              disabled={isSubmitting}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="px-4 py-2 bg-coral-500 text-white rounded-lg text-sm font-medium hover:bg-coral-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-coral-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
            >
              {isSubmitting ? 'Sparar...' : 'Spara ändringar'}
            </motion.button>
          </div>
        </form>
      </div>
    </div>
  );
};