export const generateSeoTags = ({
  title,
  description,
  canonical = 'https://flytti.se',
  type = 'website',
  image = 'https://flytti.se/og-image.jpg',
  publishedTime,
  modifiedTime,
  cityName
}: {
  title: string;
  description: string;
  canonical?: string;
  type?: string;
  image?: string;
  publishedTime?: string;
  modifiedTime?: string;
  cityName?: string;
}) => {
  const fullTitle = `${title} | Flytti.se`;
  
  const schema = {
    "@context": "https://schema.org",
    "@type": type === 'article' ? 'Article' : 'WebPage',
    "headline": title,
    "description": description,
    "image": image,
    "url": canonical,
    "datePublished": publishedTime,
    "dateModified": modifiedTime || publishedTime,
    "publisher": {
      "@type": "Organization",
      "name": "Flytti.se",
      "logo": {
        "@type": "ImageObject",
        "url": `${canonical}/logo.png`
      }
    }
  };

  if (cityName) {
    schema["@type"] = "LocalBusiness";
    schema["areaServed"] = {
      "@type": "City",
      "name": cityName,
      "containedInPlace": {
        "@type": "Country",
        "name": "Sweden"
      }
    };
  }

  return {
    title: fullTitle,
    meta: [
      { name: 'description', content: description },
      { property: 'og:title', content: fullTitle },
      { property: 'og:description', content: description },
      { property: 'og:type', content: type },
      { property: 'og:image', content: image },
      { property: 'og:url', content: canonical },
      { property: 'og:site_name', content: 'Flytti.se' },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: fullTitle },
      { name: 'twitter:description', content: description },
      { name: 'twitter:image', content: image },
      publishedTime && { property: 'article:published_time', content: publishedTime },
      modifiedTime && { property: 'article:modified_time', content: modifiedTime }
    ].filter(Boolean),
    link: [
      { rel: 'canonical', href: canonical }
    ],
    schema
  };
};