import React from 'react';
import { useFileSystem } from '../../lib/file-system';

export const FileSystemProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const lastUpdate = useFileSystem((state) => state.lastUpdate);

  React.useEffect(() => {
    // Force re-render of children when file system updates
    console.log('File system updated:', new Date(lastUpdate).toISOString());
  }, [lastUpdate]);

  return <>{children}</>;
};