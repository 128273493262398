import React from 'react';
import { motion } from 'framer-motion';
import { Box, Shield, Clock, CheckCircle } from 'lucide-react';

const services = [
  {
    title: 'Olika förvaringsstorlekar',
    description: 'Flexibla lösningar för alla behov.',
    icon: Box,
    items: [
      'Från små förråd (1-4 m²)',
      'Mellanstora utrymmen (5-15 m²)',
      'Stora lagerlokaler (16-40 m²)',
      'Anpassningsbara lösningar'
    ]
  },
  {
    title: 'Säker förvaring',
    description: 'Modern säkerhetsteknik för din trygghet.',
    icon: Shield,
    items: [
      'Larmade lokaler',
      'Kameraövervakning',
      'Personlig kod/nyckel',
      'Brandsäkra utrymmen'
    ]
  },
  {
    title: 'Flexibla avtal',
    description: 'Hyresperioder som passar dig.',
    icon: Clock,
    items: [
      'Korttidsförvaring',
      'Långtidsförvaring',
      'Månadsvis betalning',
      'Ingen bindningstid'
    ]
  },
  {
    title: 'Tilläggstjänster',
    description: 'Extra service för smidig hantering.',
    icon: CheckCircle,
    items: [
      'Flytthjälp',
      'Packmaterial',
      'Transport',
      'Försäkring'
    ]
  }
];

export const StorageServices = () => {
  return (
    <section className="relative py-32 bg-section-light overflow-hidden">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h2 className="text-4xl md:text-5xl font-playfair font-bold mb-6">
            Omfattande{' '}
            <span className="relative inline-block">
              <span className="relative z-10 text-coral-500">magasineringstjänster</span>
              <span className="absolute bottom-2 left-0 h-3 w-full bg-gradient-to-r from-coral-100 to-coral-200 -z-10" />
            </span>
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            Professionell magasinering som möter alla behov
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: [0.21, 0.45, 0.27, 0.9]
              }}
              className="group relative"
            >
              <div className="relative bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500">
                <div className="relative mb-6">
                  <div className="w-16 h-16 bg-coral-50 rounded-xl flex items-center justify-center group-hover:bg-coral-100 transition-transform duration-500 group-hover:scale-110">
                    <service.icon className="w-8 h-8 text-coral-500 transform group-hover:scale-110 transition-transform duration-500" />
                    <div className="absolute inset-0 bg-coral-200/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                  </div>
                </div>

                <h3 className="text-2xl font-playfair font-semibold mb-3 group-hover:text-coral-500 transition-colors duration-300">
                  {service.title}
                </h3>
                <p className="text-gray-600 mb-6">
                  {service.description}
                </p>

                <ul className="space-y-3">
                  {service.items.map((item, itemIndex) => (
                    <li key={itemIndex} className="flex items-center text-gray-600">
                      <div className="w-1.5 h-1.5 bg-coral-500 rounded-full mr-3" />
                      {item}
                    </li>
                  ))}
                </ul>

                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};