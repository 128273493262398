import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export interface Company {
  id: string;
  name: string;
  city: string;
  phone?: string;
  email?: string;
  website?: string;
  description?: string;
  services: string[];
  certifications: string[];
  notes?: string;
  is_verified: boolean;
  created_at: string;
}

export interface Article {
  id: string;
  title: string;
  slug: string;
  category: string;
  content: string;
  created_at: string;
  updated_at: string;
}

export const db = {
  companies: {
    getAll: async () => {
      return await supabase
        .from('companies')
        .select('*')
        .order('name');
    },
    getById: async (id: string) => {
      return await supabase
        .from('companies')
        .select('*')
        .eq('id', id)
        .single();
    },
    create: async (data: Partial<Company>) => {
      return await supabase
        .from('companies')
        .insert([data])
        .select()
        .single();
    },
    update: async (id: string, data: Partial<Company>) => {
      return await supabase
        .from('companies')
        .update(data)
        .eq('id', id)
        .select()
        .single();
    },
    delete: async (id: string) => {
      return await supabase
        .from('companies')
        .delete()
        .eq('id', id);
    }
  },
  articles: {
    getAll: async () => {
      return await supabase
        .from('articles')
        .select('*')
        .order('created_at', { ascending: false });
    },
    getById: async (id: string) => {
      return await supabase
        .from('articles')
        .select('*')
        .eq('id', id)
        .single();
    },
    getBySlug: async (slug: string) => {
      return await supabase
        .from('articles')
        .select('*')
        .eq('slug', slug)
        .single();
    },
    create: async (data: Partial<Article>) => {
      return await supabase
        .from('articles')
        .insert([data])
        .select()
        .single();
    },
    update: async (id: string, data: Partial<Article>) => {
      return await supabase
        .from('articles')
        .update(data)
        .eq('id', id)
        .select()
        .single();
    },
    delete: async (id: string) => {
      return await supabase
        .from('articles')
        .delete()
        .eq('id', id);
    }
  }
};