import { useState, useEffect } from 'react';
import { fetchStats, Stats } from '../lib/api/stats';
import toast from 'react-hot-toast';

export const useStats = () => {
  const [stats, setStats] = useState<Stats>({ companies: 0, articles: 0 });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getStats = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const data = await fetchStats();
        setStats(data);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load dashboard stats';
        setError(errorMessage);
        toast.error('Kunde inte hämta statistik');
      } finally {
        setIsLoading(false);
      }
    };

    getStats();
  }, []);

  return { stats, isLoading, error };
};