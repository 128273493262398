import React from 'react';
import { motion } from 'framer-motion';
import { Shield, CheckCircle, Award, FileCheck } from 'lucide-react';

const certifications = [
  {
    icon: Shield,
    title: 'ISO 14001',
    subtitle: 'Verifierad certifiering',
    gradient: 'from-emerald-50 via-emerald-100/50 to-emerald-50'
  },
  {
    icon: Award,
    title: 'ISO 9001',
    subtitle: 'Verifierad certifiering',
    gradient: 'from-blue-50 via-blue-100/50 to-blue-50'
  }
];

const verifications = [
  {
    icon: CheckCircle,
    title: 'Kontrollerat och verifierat företag',
    description: 'Kvalitetssäkrad verksamhet'
  },
  {
    icon: Shield,
    title: 'Kontrollerade referenser',
    description: 'Vi har verifierat tidigare kunders omdömen'
  },
  {
    icon: FileCheck,
    title: 'Verifierade uppgifter',
    description: 'All företagsinformation är kontrollerad'
  }
];

export const CertificationsSection = () => {
  return (
    <section className="py-32 bg-gradient-to-b from-white to-coral-50/10 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h2 className="text-4xl md:text-5xl font-playfair font-bold mb-6">
            Vi samarbetar endast med{' '}
            <span className="text-coral-500">verifierade företag</span>
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            För att säkerställa högsta kvalitet och trygghet för våra kunder arbetar vi uteslutande 
            med noggrant utvalda och certifierade flyttfirmor.
          </p>
        </motion.div>

        {/* ISO Certifications */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-20">
          {certifications.map((cert, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="group relative"
            >
              <div className="relative bg-white rounded-2xl p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500">
                <div className="flex items-center space-x-6">
                  <div className="relative">
                    <div className="w-16 h-16 bg-gradient-to-br from-coral-50 to-coral-100/50 rounded-xl flex items-center justify-center transition-all duration-500 group-hover:scale-110">
                      <cert.icon className="w-8 h-8 text-coral-500 transform group-hover:rotate-12 transition-all duration-500" />
                      <div className="absolute inset-0 bg-gradient-to-br from-coral-100/50 to-white rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                    </div>
                    <div className="absolute inset-0 bg-coral-200/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-playfair font-semibold mb-2 group-hover:text-coral-500 transition-colors duration-300">
                      {cert.title}
                    </h3>
                    <p className="text-gray-600">{cert.subtitle}</p>
                  </div>
                </div>
                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </div>
            </motion.div>
          ))}
        </div>

        {/* Verifications */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {verifications.map((verification, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: [0.21, 0.45, 0.27, 0.9]
              }}
              className="group relative"
            >
              <div className="relative bg-white rounded-2xl p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500">
                <div className="relative mb-6">
                  <div className="w-14 h-14 bg-gradient-to-br from-coral-50 to-coral-100/50 rounded-xl flex items-center justify-center group-hover:scale-110 transition-all duration-500">
                    <verification.icon className="w-7 h-7 text-coral-500 transform group-hover:rotate-12 transition-all duration-500" />
                    <div className="absolute inset-0 bg-gradient-to-br from-coral-100/50 to-white rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                  </div>
                  <div className="absolute inset-0 bg-coral-200/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                </div>
                <h3 className="text-xl font-semibold mb-3 group-hover:text-coral-500 transition-colors duration-300">
                  {verification.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {verification.description}
                </p>
                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};