import React from 'react';
import { Truck, Shield, Star } from 'lucide-react';
import { PageHero } from '../shared/PageHero';

export const Hero = () => {
  const stats = [
    { icon: Truck, text: "300+ flyttfirmor" },
    { icon: Shield, text: "Verifierade partners" },
    { icon: Star, text: "4.9/5 kundbetyg" }
  ];

  const handleCtaClick = () => {
    const formElement = document.getElementById('service-request-form');
    if (formElement) {
      const headerOffset = 100;
      const elementPosition = formElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <PageHero
      title="Välkommen till"
      titleHighlight="flytti.se"
      description="Jämför priser och få offerter från verifierade flyttfirmor i hela Sverige. Få kostnadsfria offerter och välj det bästa alternativet för dig."
      stats={stats}
      ctaText="Få offerter nu"
      ctaAction={handleCtaClick}
    />
  );
};