import React from 'react';
import { SEO } from '../components/shared/SEO';
import { Building2, Shield, Clock } from 'lucide-react';
import { PageHero } from '../components/shared/PageHero';
import { RecoWidget } from '../components/ui/RecoWidget';
import { OfficeMoveFeatures } from '../components/office-move/OfficeMoveFeatures';
import { OfficeMoveProcess } from '../components/office-move/OfficeMoveProcess';
import { OfficeMoveWhyUs } from '../components/office-move/OfficeMoveWhyUs';
import { OfficeMoveGuarantees } from '../components/office-move/OfficeMoveGuarantees';
import { ServiceGrid } from '../components/shared/ServiceGrid';
import { ServiceRequestForm } from '../components/shared/ServiceRequestForm';

export const OfficeMoveHelp = () => {
  const stats = [
    { icon: Building2, text: "200+ kontorsflyttar" },
    { icon: Shield, text: "Specialiserade partners" },
    { icon: Clock, text: "Minimal avbrottstid" }
  ];

  const handleCtaClick = () => {
    const formElement = document.getElementById('service-request-form');
    if (formElement) {
      const headerOffset = 100;
      const elementPosition = formElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Kontorsflytt - Specialanpassade lösningar"
        description="Professionell hjälp med kontorsflytt. Minimera avbrottstiden med specialister på företagsflytt. Jämför offerter från erfarna flyttfirmor."
      />
      <PageHero
        title="Professionell"
        titleHighlight="kontorsflytt"
        description="Vi hjälper er hitta rätt flyttfirma för er kontorsflytt. Jämför offerter och få skräddarsydda lösningar för just era behov."
        stats={stats}
        ctaText="Få offerter nu"
        ctaAction={handleCtaClick}
      />
      <RecoWidget />
      <OfficeMoveFeatures />
      <OfficeMoveProcess />
      <ServiceRequestForm />
      <OfficeMoveWhyUs />
      <OfficeMoveGuarantees />
      <ServiceGrid />
    </div>
  );
};