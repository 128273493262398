import React from 'react';
import { motion } from 'framer-motion';

export const RecoWidget = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}
      className="relative z-0 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 -mt-20"
    >
      <div className="bg-white rounded-xl shadow-premium p-6 hover:shadow-premium-hover transition-shadow duration-300">
        <iframe 
          src="https://widget.reco.se/v2/widget/5925461?mode=HORIZONTAL_QUOTE&inverted=false&border=false" 
          title="Flytti.se - Omdömen på Reco" 
          height="225" 
          className="w-full border-0 block overflow-hidden"
          data-reactroot=""
        />
      </div>
    </motion.div>
  );
};