import React from 'react';
import { motion } from 'framer-motion';
import { MessageSquare, Shield, Star } from 'lucide-react';
import { SEO } from '../components/shared/SEO';
import { ServiceRequestForm } from '../components/shared/ServiceRequestForm';

export const GetQuote = () => {
  const features = [
    {
      icon: MessageSquare,
      title: 'Snabbt & enkelt',
      description: 'Få offerter inom 24 timmar från kvalitetssäkrade företag'
    },
    {
      icon: Shield,
      title: 'Tryggt & säkert',
      description: 'Vi samarbetar endast med verifierade och försäkrade företag'
    },
    {
      icon: Star,
      title: 'Kostnadsfritt',
      description: 'Helt gratis att använda vår tjänst, utan förpliktelser'
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-coral-50/10">
      <SEO 
        title="Få kostnadsfria offerter"
        description="Jämför offerter från kvalitetssäkrade flyttfirmor. Snabbt, enkelt och kostnadsfritt. Få svar inom 24 timmar."
      />
      
      <div className="pt-32 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl md:text-5xl font-playfair font-bold mb-6">
              Få kostnadsfria{' '}
              <span className="text-coral-500">offerter</span>
            </h1>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">
              Fyll i formuläret nedan för att få offerter från kvalitetssäkrade företag. 
              Det är helt kostnadsfritt och utan förpliktelser.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white rounded-xl p-6 shadow-premium hover:shadow-premium-hover transition-all duration-500"
              >
                <div className="w-12 h-12 bg-coral-50 rounded-xl flex items-center justify-center mb-4">
                  <feature.icon className="w-6 h-6 text-coral-500" />
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>

          <div className="max-w-3xl mx-auto">
            <ServiceRequestForm />
          </div>
        </div>
      </div>
    </div>
  );
};