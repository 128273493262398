import React from 'react';
import { SEO } from '../components/shared/SEO';
import { CompanyList } from '../components/companies/CompanyList';
import { CompanySearchForm } from '../components/companies/CompanySearchForm';
import { useCompanies } from '../hooks/useCompanies';

export const CompanySearch = () => {
  const { companies, isLoading, error } = useCompanies();
  const [filteredCompanies, setFilteredCompanies] = React.useState(companies);

  // Update filtered companies when main companies list changes
  React.useEffect(() => {
    setFilteredCompanies(companies);
  }, [companies]);

  const handleSearch = React.useCallback(({ query, service, city, verifiedOnly }) => {
    const filtered = companies.filter(company => {
      const matchesQuery = !query || 
        company.name.toLowerCase().includes(query.toLowerCase()) ||
        company.city.toLowerCase().includes(query.toLowerCase());
      
      const matchesService = !service || 
        company.services.includes(service);
      
      const matchesCity = !city || 
        company.city.toLowerCase().includes(city.toLowerCase());
      
      const matchesVerified = !verifiedOnly || 
        company.is_verified;

      return matchesQuery && matchesService && matchesCity && matchesVerified;
    });

    setFilteredCompanies(filtered);
  }, [companies]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <SEO 
        title="Hitta kvalitetssäkrade företag"
        description="Jämför och hitta rätt företag för dina behov. Filtrera på tjänster, stad och verifierade företag för att hitta den perfekta flyttfirman."
      />
      
      <section className="pt-32 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-playfair font-bold text-gray-900 mb-6">
              Hitta kvalitetssäkrade företag
            </h1>
            <p className="text-lg md:text-xl text-gray-600 max-w-3xl mx-auto">
              Jämför och hitta rätt företag för dina behov
            </p>
          </div>

          <div className="bg-white rounded-2xl shadow-premium p-8 mb-16">
            <CompanySearchForm 
              onSearch={handleSearch}
              companies={companies}
            />
          </div>

          <div className="mt-16">
            {isLoading ? (
              <div className="flex justify-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-coral-500" />
              </div>
            ) : error ? (
              <div className="text-center py-12 bg-white rounded-2xl shadow-sm">
                <p className="text-gray-600">
                  Ett fel uppstod vid hämtning av företag. Försök igen senare.
                </p>
              </div>
            ) : filteredCompanies.length > 0 ? (
              <CompanyList companies={filteredCompanies} />
            ) : (
              <div className="text-center py-12 bg-white rounded-2xl shadow-sm">
                <p className="text-gray-600">
                  Inga företag hittades som matchar din sökning.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};