import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { LazyImage } from '../shared/LazyImage';

export const MovingWhyUs = () => {
  return (
    <section className="py-16 md:py-24 bg-gradient-to-b from-white to-coral-50/10 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16 items-center">
          {/* Image Column */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="relative order-2 lg:order-1"
          >
            <div className="aspect-[4/3] rounded-2xl overflow-hidden shadow-premium">
              <LazyImage
                src="https://res.cloudinary.com/acasting-prod/image/upload/v1733523409/blog-articles/mtjnfbdim5bkslpbqx4k.jpg"
                alt="Flytti.se - Flytthjälp"
                className="w-full h-full object-cover"
                priority={true}
              />
              <div className="absolute inset-0 bg-gradient-to-br from-coral-500/20 to-transparent" />
            </div>
            
            {/* Premium Image Effects */}
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="absolute -inset-4 bg-gradient-to-br from-coral-100/30 to-transparent rounded-2xl blur-2xl -z-10"
            />
          </motion.div>

          {/* Content Column */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="order-1 lg:order-2 space-y-6 md:space-y-8"
          >
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-playfair font-bold leading-tight">
              Varför anlita en{' '}
              <span className="text-coral-500">flyttfirma?</span>
            </h2>

            <div className="space-y-6">
              <p className="text-gray-600 text-lg leading-relaxed">
                Med en professionell flyttfirma blir upplevelsen en helt annan. Istället för att slita 
                med tunga lådor och möbler i trånga trappor eller kämpa med att få plats med allt i en 
                liten hyrbil, kan du fokusera på de positiva aspekterna av din flytt.
              </p>

              <p className="text-gray-600 text-lg leading-relaxed">
                Proffsen tar hand om allt det tunga arbetet och logistiken, vilket ger dig friheten att 
                planera ditt nya hem i lugn och ro.
              </p>
            </div>

            <Link
              to="/fa-offert"
              className="inline-flex items-center px-8 py-4 bg-coral-500 text-white rounded-xl font-medium shadow-lg hover:bg-coral-600 transition-all duration-300 group"
            >
              <span>Få offerter nu</span>
              <ArrowRight className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};