import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Clock, TrendingDown, CheckCircle } from 'lucide-react';

const benefits = [
  {
    icon: Shield,
    title: 'Professionell flytthjälp',
    description: 'Erfarna flyttare med rätt kompetens och utrustning'
  },
  {
    icon: Clock,
    title: 'Spara tid',
    description: 'Låt proffsen sköta det tunga arbetet medan du fokuserar på annat'
  },
  {
    icon: TrendingDown,
    title: 'Konkurrenskraftigt',
    description: 'Jämför priser från kvalitetssäkrade flyttfirmor'
  },
  {
    icon: CheckCircle,
    title: 'Garanterat resultat',
    description: 'Säker och professionell hantering med nöjd-kund-garanti'
  }
];

export const MovingBenefits = () => {
  return (
    <section className="relative py-24 overflow-hidden">
      {/* Premium Background */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_top_right,_var(--tw-gradient-stops))] from-coral-50 via-white to-white" />
      
      {/* Decorative Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-br from-coral-100/30 to-transparent rounded-full blur-3xl" />
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-gradient-to-tr from-coral-50/50 to-transparent rounded-full blur-3xl" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: [0.21, 0.45, 0.27, 0.9]
              }}
              className="group h-full"
            >
              <div className="relative bg-white/80 backdrop-blur-sm rounded-2xl p-6 md:p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500 h-full flex flex-col">
                {/* Icon Container */}
                <div className="relative mb-6 flex-shrink-0">
                  <div className="w-14 h-14 bg-coral-50 rounded-xl flex items-center justify-center group-hover:bg-coral-100 transition-transform duration-500 group-hover:scale-110">
                    <benefit.icon className="w-7 h-7 text-coral-500 transform group-hover:scale-110 transition-transform duration-500" />
                    <div className="absolute inset-0 bg-coral-200/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                  </div>
                </div>

                {/* Content */}
                <div className="flex-grow flex flex-col">
                  <h3 className="text-xl font-semibold mb-3 group-hover:text-coral-500 transition-colors duration-300">
                    {benefit.title}
                  </h3>
                  <p className="text-gray-600 leading-relaxed flex-grow">
                    {benefit.description}
                  </p>
                </div>

                {/* Bottom Highlight */}
                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};