import React from 'react';
import { DashboardStats } from '../../components/admin/DashboardStats';

export const AdminDashboard = () => {
  return (
    <div className="p-4 sm:p-6 lg:p-8 max-w-7xl mx-auto">
      <div className="mb-8">
        <h1 className="text-2xl sm:text-3xl font-semibold text-gray-900">
          Översikt över plattformens aktivitet
        </h1>
      </div>

      <DashboardStats />
    </div>
  );
};