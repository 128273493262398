import { supabase } from '../supabase';

export interface Stats {
  companies: number;
  articles: number;
}

export const fetchStats = async (): Promise<Stats> => {
  try {
    const [companiesResponse, articlesResponse] = await Promise.all([
      supabase.from('companies').select('id', { count: 'exact' }),
      supabase.from('articles').select('id', { count: 'exact' })
    ]);

    if (companiesResponse.error) throw companiesResponse.error;
    if (articlesResponse.error) throw articlesResponse.error;

    return {
      companies: companiesResponse.count || 0,
      articles: articlesResponse.count || 0
    };
  } catch (error) {
    console.error('Error fetching stats:', error);
    throw new Error('Failed to fetch dashboard stats');
  }
};