import { useState, useEffect } from 'react';
import { Company, db } from '../lib/supabase';
import toast from 'react-hot-toast';

export const useCompany = (id?: string) => {
  const [company, setCompany] = useState<Company | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCompany = async () => {
      if (!id) return;

      try {
        setIsLoading(true);
        setError(null);
        
        const { data, error: dbError } = await db.companies.getById(id);
        
        if (dbError) throw dbError;
        if (!data) throw new Error('Company not found');
        
        setCompany(data);
      } catch (err) {
        console.error('Error fetching company:', err);
        setError(err instanceof Error ? err.message : 'Failed to load company');
        toast.error('Kunde inte hämta företaget');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCompany();
  }, [id]);

  return { company, isLoading, error };
};