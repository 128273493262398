import React from 'react';
import { SEO } from '../components/shared/SEO';
import { Box, Shield, Clock } from 'lucide-react';
import { PageHero } from '../components/shared/PageHero';
import { RecoWidget } from '../components/ui/RecoWidget';
import { StorageFeatures } from '../components/storage/StorageFeatures';
import { StorageProcess } from '../components/storage/StorageProcess';
import { StorageServices } from '../components/storage/StorageServices';
import { StorageWhyUs } from '../components/storage/StorageWhyUs';
import { StorageGuarantees } from '../components/storage/StorageGuarantees';
import { ServiceGrid } from '../components/shared/ServiceGrid';
import { ServiceRequestForm } from '../components/shared/ServiceRequestForm';

export const StorageHelp = () => {
  const stats = [
    { icon: Box, text: "Olika storlekar" },
    { icon: Shield, text: "Säker förvaring" },
    { icon: Clock, text: "Flexibel lagring" }
  ];

  const handleCtaClick = () => {
    const formElement = document.getElementById('service-request-form');
    if (formElement) {
      const headerOffset = 100;
      const elementPosition = formElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Magasinering - Säker förvaring"
        description="Hitta säker och flexibel magasinering för dina behov. Jämför priser från kvalitetssäkrade magasineringsföretag. Larmade och övervakade lokaler."
      />
      <PageHero
        title="Säker"
        titleHighlight="magasinering"
        description="Vi hjälper dig hitta rätt magasinering för dina behov. Jämför priser från kvalitetssäkrade företag med larmade och övervakade lokaler."
        stats={stats}
        ctaText="Få offerter nu"
        ctaAction={handleCtaClick}
      />
      <RecoWidget />
      <StorageFeatures />
      <StorageProcess />
      <ServiceRequestForm />
      <StorageServices />
      <StorageWhyUs />
      <StorageGuarantees />
      <ServiceGrid />
    </div>
  );
};