import React from 'react';
import { SEO } from '../components/shared/SEO';
import { Hero } from '../components/home/Hero';
import { RecoWidget } from '../components/ui/RecoWidget';
import { ServicesSection } from '../components/home/ServicesSection';
import { HowItWorks } from '../components/home/HowItWorks';
import { BenefitsSection } from '../components/home/BenefitsSection';
import { CertificationsSection } from '../components/home/CertificationsSection';
import { CoverageSection } from '../components/home/CoverageSection';
import { TrustSection } from '../components/home/TrustSection';
import { ServiceRequestForm } from '../components/shared/ServiceRequestForm';
import { FAQ } from '../components/shared/FAQ';
import { homeFAQ } from '../data/faqContent';

export const Home = () => {
  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Flytti.se - Hitta rätt flyttfirma"
        description="Jämför priser och få offerter från kvalitetssäkrade flyttfirmor i hela Sverige. Vi hjälper dig hitta rätt flyttfirma för dina behov. Kostnadsfritt och utan förpliktelser."
        schema={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "Flytti.se",
          "url": "https://flytti.se",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://flytti.se/foretag?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        }}
      />
      <Hero />
      <RecoWidget />
      <ServicesSection />
      <HowItWorks />
      <TrustSection />
      <ServiceRequestForm />
      <BenefitsSection />
      <CertificationsSection />
      <CoverageSection />
      <FAQ 
        items={homeFAQ} 
        title="Vanliga frågor om flyttjänster"
      />
    </div>
  );
};