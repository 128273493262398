import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Cookie, X } from 'lucide-react';

export const CookieBanner = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const hasConsent = localStorage.getItem('cookieConsent');
    if (!hasConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'false');
    setIsVisible(false);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 100 }}
          className="fixed bottom-0 left-0 right-0 z-50 p-4"
        >
          <div className="max-w-7xl mx-auto">
            <div className="bg-white rounded-2xl shadow-premium p-6 md:p-8">
              <div className="flex flex-col md:flex-row items-start md:items-center gap-6">
                <div className="flex-shrink-0">
                  <div className="w-12 h-12 bg-coral-50 rounded-xl flex items-center justify-center">
                    <Cookie className="w-6 h-6 text-coral-500" />
                  </div>
                </div>

                <div className="flex-grow">
                  <h3 className="text-lg font-semibold text-gray-900 mb-2">
                    Vi använder cookies
                  </h3>
                  <p className="text-gray-600">
                    Vi använder cookies för att förbättra din upplevelse på vår webbplats. 
                    Genom att fortsätta använda webbplatsen godkänner du användningen av cookies.
                  </p>
                </div>

                <div className="flex flex-col sm:flex-row gap-3 md:flex-shrink-0 w-full sm:w-auto">
                  <button
                    onClick={handleDecline}
                    className="px-6 py-2 rounded-lg border-2 border-gray-200 text-gray-600 hover:bg-gray-50 transition-colors duration-300 w-full sm:w-auto"
                  >
                    Avböj
                  </button>
                  <button
                    onClick={handleAccept}
                    className="px-6 py-2 rounded-lg bg-coral-500 text-white hover:bg-coral-600 transition-colors duration-300 w-full sm:w-auto"
                  >
                    Acceptera
                  </button>
                </div>

                <button
                  onClick={handleDecline}
                  className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 md:hidden"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};