import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  Truck, Phone, Mail, MapPin, 
  Facebook, Instagram, Linkedin,
  ExternalLink
} from 'lucide-react';

const services = [
  { name: 'Hem', href: '/' },
  { name: 'Flytthjälp', href: '/flytthjalp' },
  { name: 'Kontorsflytt', href: '/kontorsflytt' },
  { name: 'Utlandsflytt', href: '/utlandsflytt' },
  { name: 'Flyttstädning', href: '/flyttstadning' },
  { name: 'Magasinering', href: '/magasinering' },
  { name: 'Dödsbo', href: '/dodsbo' }
];

const company = [
  { name: 'Sök företag', href: '/foretag' },
  { name: 'Sök stad', href: '/stader' },
  { name: 'Anslut företag', href: '/anslut-foretag' },
  { name: 'Artiklar', href: '/artiklar' }
];

const legal = [
  { name: 'Dataskyddspolicy', href: '/dataskyddspolicy' },
  { name: 'Användarvillkor', href: '/anvandarvillkor' },
  { name: 'Cookies', href: '/cookies' }
];

const socialLinks = [
  { 
    icon: Facebook, 
    href: 'https://www.facebook.com/share/12CvSpU23G7/?mibextid=LQQJ4d',
    label: 'Facebook'
  },
  { 
    icon: Instagram, 
    href: 'https://www.instagram.com/flytti.se?igsh=dnQ2Y2NjNXh0MXc0',
    label: 'Instagram'
  },
  { 
    icon: Linkedin, 
    href: 'https://www.linkedin.com/company/flytti-se/',
    label: 'LinkedIn'
  }
];

export const Footer = () => {
  return (
    <footer className="relative bg-gradient-to-b from-white to-coral-50/20">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-coral-50/50 via-transparent to-transparent opacity-50" />
      
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 lg:gap-16">
          {/* Company Info */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="space-y-8"
          >
            <Link 
              to="/" 
              className="group flex items-center space-x-3 transition-transform duration-300 hover:-translate-y-0.5"
            >
              <div className="relative">
                <div className="absolute inset-0 bg-coral-200/20 rounded-lg blur-lg transform group-hover:scale-110 transition-transform duration-300" />
                <div className="relative bg-gradient-to-br from-coral-500 to-coral-600 p-2 rounded-lg shadow-lg">
                  <Truck className="h-6 w-6 text-white transform group-hover:rotate-12 transition-transform duration-300" />
                </div>
              </div>
              <span className="text-2xl font-playfair font-bold bg-gradient-to-r from-coral-600 to-coral-500 bg-clip-text text-transparent">
                Flytti.se
              </span>
            </Link>

            <p className="text-gray-600 leading-relaxed">
              Vi hjälper dig hitta rätt flyttfirma för dina behov. Jämför priser och få offerter från 
              kvalitetssäkrade flyttfirmor i hela Sverige.
            </p>

            <div className="flex items-center space-x-5">
              {socialLinks.map((link, index) => (
                <motion.a
                  key={index}
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={link.label}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className="group relative p-2"
                >
                  <div className="absolute inset-0 bg-coral-100 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <link.icon className="w-5 h-5 text-coral-500 relative z-10" />
                </motion.a>
              ))}
            </div>
          </motion.div>

          {/* Services */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <h3 className="text-gray-900 font-semibold mb-6">Tjänster</h3>
            <ul className="space-y-4">
              {services.map((item) => (
                <motion.li 
                  key={item.name}
                  whileHover={{ x: 4 }}
                  transition={{ duration: 0.2 }}
                >
                  <Link 
                    to={item.href}
                    className="text-gray-600 hover:text-coral-500 transition-colors duration-300 flex items-center"
                  >
                    <span className="relative">
                      <span className="absolute -left-4 top-1/2 w-2 h-0.5 bg-coral-500 opacity-0 transform -translate-y-1/2 transition-all duration-300" />
                      {item.name}
                    </span>
                  </Link>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          {/* Company */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h3 className="text-gray-900 font-semibold mb-6">Företag</h3>
            <ul className="space-y-4">
              {company.map((item) => (
                <motion.li 
                  key={item.name}
                  whileHover={{ x: 4 }}
                  transition={{ duration: 0.2 }}
                >
                  <Link 
                    to={item.href}
                    className="text-gray-600 hover:text-coral-500 transition-colors duration-300 flex items-center"
                  >
                    <span className="relative">
                      <span className="absolute -left-4 top-1/2 w-2 h-0.5 bg-coral-500 opacity-0 transform -translate-y-1/2 transition-all duration-300" />
                      {item.name}
                    </span>
                  </Link>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          {/* Contact */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h3 className="text-gray-900 font-semibold mb-6">Kontakt</h3>
            <ul className="space-y-4">
              <motion.li 
                whileHover={{ x: 4 }}
                transition={{ duration: 0.2 }}
                className="flex items-center space-x-3"
              >
                <div className="w-10 h-10 bg-coral-50 rounded-lg flex items-center justify-center">
                  <Phone className="w-5 h-5 text-coral-500" />
                </div>
                <a 
                  href="tel:0733242455"
                  className="text-gray-600 hover:text-coral-500 transition-colors duration-300"
                >
                  073-324 24 55
                </a>
              </motion.li>

              <motion.li 
                whileHover={{ x: 4 }}
                transition={{ duration: 0.2 }}
                className="flex items-center space-x-3"
              >
                <div className="w-10 h-10 bg-coral-50 rounded-lg flex items-center justify-center">
                  <Mail className="w-5 h-5 text-coral-500" />
                </div>
                <a 
                  href="mailto:info@flytti.se"
                  className="text-gray-600 hover:text-coral-500 transition-colors duration-300"
                >
                  info@flytti.se
                </a>
              </motion.li>

              <motion.li 
                whileHover={{ x: 4 }}
                transition={{ duration: 0.2 }}
                className="flex items-center space-x-3"
              >
                <div className="w-10 h-10 bg-coral-50 rounded-lg flex items-center justify-center">
                  <MapPin className="w-5 h-5 text-coral-500" />
                </div>
                <span className="text-gray-600">Stockholm, Sverige</span>
              </motion.li>
            </ul>
          </motion.div>
        </div>

        {/* Bottom section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="mt-16 pt-8 border-t border-gray-100"
        >
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="text-sm text-gray-500 flex items-center space-x-2">
              <span>© 2024 Flytti.se</span>
              <span className="w-1 h-1 bg-gray-300 rounded-full" />
              <span className="flex items-center">
                Utvecklad av{' '}
                <a 
                  href="https://almfors.se" 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-coral-500 hover:text-coral-600 ml-1 inline-flex items-center"
                >
                  Almfors
                  <ExternalLink className="w-3 h-3 ml-1" />
                </a>
              </span>
            </div>

            <div className="flex space-x-6">
              {legal.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="text-sm text-gray-500 hover:text-coral-500 transition-colors duration-300"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </footer>
  );
};