import React from 'react';
import ReactMarkdown from 'react-markdown';

interface ArticleContentProps {
  content: string;
}

export const ArticleContent = ({ content }: ArticleContentProps) => {
  return (
    <div className="prose prose-lg max-w-none">
      <ReactMarkdown
        components={{
          h1: ({ children }) => (
            <h1 className="text-3xl font-bold text-gray-900 mb-8 mt-12 font-playfair">
              {children}
            </h1>
          ),
          h2: ({ children }) => (
            <h2 className="text-2xl font-bold text-gray-900 mb-6 mt-10 font-playfair">
              {children}
            </h2>
          ),
          h3: ({ children }) => (
            <h3 className="text-xl font-bold text-gray-900 mb-4 mt-8 font-playfair">
              {children}
            </h3>
          ),
          p: ({ children }) => (
            <p className="text-gray-700 leading-relaxed mb-6 text-lg">
              {children}
            </p>
          ),
          ul: ({ children }) => (
            <ul className="space-y-4 mb-8 pl-0">
              {children}
            </ul>
          ),
          ol: ({ children }) => (
            <ol className="space-y-4 mb-8 pl-0 list-none">
              {children}
            </ol>
          ),
          li: ({ children, ordered, index }) => {
            if (ordered) {
              return (
                <li className="flex items-start gap-4 text-gray-700">
                  <span className="font-semibold text-coral-500 flex-shrink-0">
                    {index + 1}.
                  </span>
                  <div className="flex-1">{children}</div>
                </li>
              );
            }
            return (
              <li className="flex items-start gap-4 text-gray-700">
                <span className="text-coral-500 flex-shrink-0">•</span>
                <div className="flex-1">{children}</div>
              </li>
            );
          },
          strong: ({ children }) => (
            <strong className="font-semibold text-gray-900">
              {children}
            </strong>
          ),
          em: ({ children }) => (
            <em className="italic text-gray-800">
              {children}
            </em>
          ),
          blockquote: ({ children }) => (
            <blockquote className="border-l-4 border-coral-500 pl-6 py-1 my-8 italic text-gray-700">
              {children}
            </blockquote>
          ),
          a: ({ children, href }) => (
            <a 
              href={href}
              className="text-coral-500 hover:text-coral-600 underline transition-colors"
              target="_blank"
              rel="noopener noreferrer"
            >
              {children}
            </a>
          )
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};