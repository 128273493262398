import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Clock, TrendingDown, Box } from 'lucide-react';

const features = [
  {
    icon: Shield,
    title: 'Säker förvaring',
    description: 'Larmade och övervakade lokaler med modern säkerhetsteknik'
  },
  {
    icon: Clock,
    title: 'Flexibel lagring',
    description: 'Kort- och långtidsförvaring efter dina behov'
  },
  {
    icon: TrendingDown,
    title: 'Konkurrenskraftigt',
    description: 'Jämför priser från kvalitetssäkrade magasineringsföretag'
  },
  {
    icon: Box,
    title: 'Olika storlekar',
    description: 'Från små förråd till stora lagerlokaler'
  }
];

export const StorageFeatures = () => {
  return (
    <section className="relative py-32 bg-section-light">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: [0.21, 0.45, 0.27, 0.9]
              }}
              className="group h-full"
            >
              <div className="relative bg-white/80 backdrop-blur-sm rounded-2xl p-6 md:p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500 h-full flex flex-col">
                {/* Icon Container */}
                <div className="relative mb-6 flex-shrink-0">
                  <div className="w-14 h-14 bg-coral-50 rounded-xl flex items-center justify-center group-hover:bg-coral-100 transition-transform duration-500 group-hover:scale-110">
                    <feature.icon className="w-7 h-7 text-coral-500 transform group-hover:scale-110 transition-transform duration-500" />
                  </div>
                </div>

                {/* Content */}
                <div className="flex-grow flex flex-col">
                  <h3 className="text-xl font-semibold mb-3 group-hover:text-coral-500 transition-colors duration-300">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600 leading-relaxed flex-grow">
                    {feature.description}
                  </p>
                </div>

                {/* Bottom Highlight */}
                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};