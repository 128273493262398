export const homeFAQ = [
  {
    question: "Hur hittar jag rätt flyttfirma?",
    answer: "Genom vår tjänst får du offerter från noggrant utvalda och kvalitetssäkrade flyttfirmor. Vi kontrollerar att alla företag har rätt tillstånd, försäkringar och goda referenser. Du kan enkelt jämföra priser och tjänster för att hitta den flyttfirma som bäst matchar dina behov."
  },
  {
    question: "Vad kostar det att använda Flytti.se?",
    answer: "Det är helt kostnadsfritt att använda vår tjänst. Du får offerter från flera flyttfirmor utan några förpliktelser. Du betalar endast om du väljer att anlita någon av flyttfirmorna."
  },
  {
    question: "Hur snabbt får jag svar på min förfrågan?",
    answer: "Normalt får du svar inom 24 timmar. Många gånger får du första offerten redan inom någon timme. Vi samarbetar med över 300 flyttfirmor i hela Sverige för att säkerställa snabb respons."
  },
  {
    question: "Är företagen på Flytti.se kvalitetssäkrade?",
    answer: "Ja, vi genomför noggranna kontroller av alla företag. Vi verifierar deras tillstånd, försäkringar och tidigare kundrecensioner. Endast företag som uppfyller våra höga kvalitetskrav får vara anslutna till vår tjänst."
  },
  {
    question: "Vilka tjänster kan jag få hjälp med?",
    answer: "Vi erbjuder hjälp med flyttjänster, kontorsflytt, utlandsflytt, flyttstädning, magasinering och dödsbohantering. Alla tjänster utförs av kvalitetssäkrade företag med rätt kompetens och erfarenhet."
  },
  {
    question: "Hur fungerar processen?",
    answer: "1. Fyll i vårt enkla formulär med dina behov\n2. Få offerter från upp till tre lämpliga företag\n3. Jämför offerterna och välj det alternativ som passar dig bäst\n4. Boka tjänsten direkt med företaget"
  }
];