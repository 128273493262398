import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Plus, Search, Pencil, Trash2, Eye, Shield } from 'lucide-react';
import { Company } from '../../lib/supabase';
import { CompanyDetails } from '../../components/admin/CompanyDetails';
import { useAdminStore } from '../../hooks/useAdminData';
import { AdminBackButton } from '../../components/admin/AdminBackButton';

export const AdminCompanies = () => {
  const navigate = useNavigate();
  const { companies, fetchCompanies, deleteCompany, isLoading, error } = useAdminStore();
  const [searchParams, setSearchParams] = React.useState({
    query: '',
    service: '',
    verifiedOnly: false
  });
  const [selectedCompany, setSelectedCompany] = React.useState<Company | null>(null);
  const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);

  React.useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  const handleDelete = async (id: string) => {
    if (window.confirm('Är du säker på att du vill ta bort detta företag?')) {
      await deleteCompany(id);
    }
  };

  const filteredCompanies = React.useMemo(() => {
    return companies.filter(company => {
      const matchesSearch = company.name.toLowerCase().includes(searchParams.query.toLowerCase()) ||
                         company.city.toLowerCase().includes(searchParams.query.toLowerCase());
      const matchesVerified = !searchParams.verifiedOnly || company.is_verified;
      return matchesSearch && matchesVerified;
    });
  }, [companies, searchParams]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-coral-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 sm:p-6 lg:p-8">
        <div className="bg-red-50 rounded-xl p-6 text-center">
          <p className="text-red-600 mb-4">Ett fel uppstod när företagen skulle hämtas.</p>
          <button
            onClick={() => fetchCompanies()}
            className="text-coral-500 hover:text-coral-600 font-medium"
          >
            Försök igen
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 sm:p-6 lg:p-8">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <AdminBackButton />
          <h1 className="text-2xl font-semibold text-gray-900">
            Hantera företag
          </h1>
        </div>
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => navigate('/admin/companies/new')}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-coral-500 hover:bg-coral-600"
        >
          <Plus className="h-4 w-4 mr-2" />
          Nytt företag
        </motion.button>
      </div>

      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="p-4 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                <input
                  type="text"
                  placeholder="Sök på namn eller stad..."
                  value={searchParams.query}
                  onChange={(e) => setSearchParams({ ...searchParams, query: e.target.value })}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                />
              </div>
            </div>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={searchParams.verifiedOnly}
                onChange={(e) => setSearchParams({ ...searchParams, verifiedOnly: e.target.checked })}
                className="rounded border-gray-300 text-coral-500 focus:ring-coral-500"
              />
              <span className="text-sm text-gray-600">Endast verifierade</span>
            </label>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Företag
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Stad
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Åtgärder
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredCompanies.map((company) => (
                <tr key={company.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">{company.name}</div>
                    <div className="text-sm text-gray-500">{company.email}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {company.city}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      company.is_verified
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      <Shield className="w-3 h-3 mr-1" />
                      {company.is_verified ? 'Verifierad' : 'Ej verifierad'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => {
                        setSelectedCompany(company);
                        setIsDetailsOpen(true);
                      }}
                      className="text-gray-400 hover:text-gray-500 mx-2"
                    >
                      <Eye className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => navigate(`/admin/companies/${company.id}/edit`)}
                      className="text-coral-400 hover:text-coral-500 mx-2"
                    >
                      <Pencil className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(company.id)}
                      className="text-red-400 hover:text-red-500 mx-2"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedCompany && isDetailsOpen && (
        <CompanyDetails
          company={selectedCompany}
          onClose={() => {
            setIsDetailsOpen(false);
            setSelectedCompany(null);
          }}
        />
      )}
    </div>
  );
};