import React from 'react';
import { motion } from 'framer-motion';

export const BackgroundCircles = () => {
  return (
    <div className="absolute inset-0 overflow-hidden pointer-events-none">
      {/* Top right circle */}
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 0.25, scale: 1 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
        className="absolute -top-20 -right-20 w-96 h-96 bg-coral-300 rounded-full filter blur-3xl"
      />

      {/* Bottom left circle */}
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 0.3, scale: 1 }}
        transition={{ duration: 1.5, delay: 0.2, ease: "easeOut" }}
        className="absolute -bottom-32 -left-32 w-[600px] h-[600px] bg-coral-200 rounded-full filter blur-3xl"
      />

      {/* Middle floating circle */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 0.2, y: 0 }}
        transition={{ duration: 1, delay: 0.4, ease: "easeOut" }}
        className="absolute top-1/2 left-1/4 w-64 h-64 bg-coral-400 rounded-full filter blur-2xl animate-float"
      />
    </div>
  );
};