import React from 'react';
import { motion } from 'framer-motion';
import { Shield, CheckCircle, Users } from 'lucide-react';

const guarantees = [
  {
    icon: Shield,
    title: 'Specialiserade partners',
    description: 'Erfarna kontorsflyttare med rätt kompetens'
  },
  {
    icon: CheckCircle,
    title: 'Fullständig försäkring',
    description: 'Omfattande försäkringsskydd för er trygghet'
  },
  {
    icon: Users,
    title: 'Projektledning',
    description: 'Dedikerad kontaktperson genom hela processen'
  }
];

export const OfficeMoveGuarantees = () => {
  return (
    <section className="relative py-32 overflow-hidden">
      {/* Premium Background */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-b from-coral-50/30 via-white to-coral-50/20" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-coral-100/10 via-transparent to-transparent" />
      </div>

      {/* Decorative Elements */}
      <div className="absolute inset-0">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="absolute top-1/4 -left-1/4 w-1/2 h-1/2 bg-gradient-to-r from-coral-100/30 to-transparent rounded-full blur-3xl"
        />
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.3 }}
          className="absolute bottom-1/4 -right-1/4 w-1/2 h-1/2 bg-gradient-to-l from-coral-50/50 to-transparent rounded-full blur-3xl"
        />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h2 className="text-4xl md:text-5xl font-playfair font-bold mb-6">
            Er trygghet är vår{' '}
            <span className="relative inline-block">
              <span className="relative z-10 text-coral-500">prioritet</span>
              <motion.span
                initial={{ width: '0%' }}
                whileInView={{ width: '100%' }}
                transition={{ duration: 1, delay: 0.5 }}
                className="absolute bottom-2 left-0 h-3 bg-gradient-to-r from-coral-100 to-coral-200 -z-10"
              />
            </span>
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            Vi samarbetar endast med erfarna och professionella kontorsflyttare som förstår era specifika behov.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {guarantees.map((guarantee, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: [0.21, 0.45, 0.27, 0.9]
              }}
              className="group relative"
            >
              <div className="relative bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500">
                {/* Premium Icon Container */}
                <div className="relative mb-6">
                  <div className="w-16 h-16 bg-coral-50 rounded-xl flex items-center justify-center group-hover:bg-coral-100 transition-transform duration-500 group-hover:scale-110">
                    <guarantee.icon className="w-8 h-8 text-coral-500 transform group-hover:scale-110 transition-transform duration-500" />
                    {/* Glow Effect */}
                    <div className="absolute inset-0 bg-coral-200/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                  </div>
                  {/* Additional Decorative Elements */}
                  <div className="absolute -inset-1 bg-gradient-to-r from-coral-200/0 via-coral-200/30 to-coral-200/0 rounded-3xl blur-lg opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                </div>

                {/* Content */}
                <h3 className="text-xl font-semibold mb-3 group-hover:text-coral-500 transition-colors duration-300">
                  {guarantee.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {guarantee.description}
                </p>

                {/* Bottom Highlight */}
                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};