import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { Article } from '../../lib/supabase';
import { useArticleById } from '../../hooks/useArticleById';
import { createArticle, updateArticle } from '../../lib/api/articles';
import toast from 'react-hot-toast';

export const EditArticle = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { article, isLoading, error } = useArticleById(id);
  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm<Article>();

  React.useEffect(() => {
    if (article) {
      Object.entries(article).forEach(([key, value]) => {
        setValue(key as keyof Article, value);
      });
    }
  }, [article, setValue]);

  // Auto-generate slug from title
  React.useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'title' && value.title) {
        const slug = value.title
          .toLowerCase()
          .replace(/[åä]/g, 'a')
          .replace(/[ö]/g, 'o')
          .replace(/[^a-z0-9]+/g, '-')
          .replace(/^-+|-+$/g, '');
        setValue('slug', slug);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const onSubmit = async (data: Article) => {
    try {
      if (id) {
        await updateArticle(id, data);
        toast.success('Artikeln har uppdaterats');
      } else {
        await createArticle(data);
        toast.success('Artikeln har skapats');
      }
      navigate('/admin/articles');
    } catch (error) {
      console.error('Error saving article:', error);
      toast.error('Ett fel uppstod när artikeln skulle sparas');
    }
  };

  const categories = [
    'Flytthjälp',
    'Kontorsflytt',
    'Utlandsflytt',
    'Flyttstädning',
    'Magasinering',
    'Dödsbo',
    'Guide'
  ];

  if (isLoading) {
    return (
      <div className="p-4 sm:p-6 lg:p-8">
        <div className="animate-pulse space-y-4">
          <div className="h-8 w-64 bg-gray-200 rounded" />
          <div className="h-12 w-full bg-gray-200 rounded" />
          <div className="h-12 w-full bg-gray-200 rounded" />
          <div className="h-48 w-full bg-gray-200 rounded" />
        </div>
      </div>
    );
  }

  if (error && id) {
    return (
      <div className="p-4 sm:p-6 lg:p-8">
        <div className="bg-red-50 rounded-xl p-6 text-center">
          <p className="text-red-600 mb-4">Ett fel uppstod när artikeln skulle hämtas.</p>
          <button
            onClick={() => navigate('/admin/articles')}
            className="text-coral-500 hover:text-coral-600 font-medium"
          >
            Tillbaka till artiklar
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 sm:p-6 lg:p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center gap-4 mb-8">
          <button
            onClick={() => navigate('/admin/articles')}
            className="text-gray-600 hover:text-gray-900 transition-colors"
          >
            <ArrowLeft className="w-6 h-6" />
          </button>
          <h1 className="text-2xl font-semibold text-gray-900">
            {id ? 'Redigera artikel' : 'Ny artikel'}
          </h1>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div className="bg-white rounded-xl shadow-sm p-6">
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Titel
                </label>
                <input
                  type="text"
                  {...register('title', { required: 'Detta fält är obligatoriskt' })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                />
                {errors.title && (
                  <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  URL-slug
                </label>
                <input
                  type="text"
                  {...register('slug', { required: 'Detta fält är obligatoriskt' })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent bg-gray-50"
                  readOnly
                />
                {errors.slug && (
                  <p className="mt-1 text-sm text-red-600">{errors.slug.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Kategori
                </label>
                <select
                  {...register('category', { required: 'Detta fält är obligatoriskt' })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                >
                  <option value="">Välj kategori</option>
                  {categories.map(category => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
                {errors.category && (
                  <p className="mt-1 text-sm text-red-600">{errors.category.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Innehåll
                </label>
                <textarea
                  {...register('content', { required: 'Detta fält är obligatoriskt' })}
                  rows={20}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent font-mono"
                />
                {errors.content && (
                  <p className="mt-1 text-sm text-red-600">{errors.content.message}</p>
                )}
              </div>
            </div>

            <div className="flex justify-end space-x-3 mt-6 pt-6 border-t">
              <button
                type="button"
                onClick={() => navigate('/admin/articles')}
                className="px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Avbryt
              </button>
              <motion.button
                type="submit"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="px-4 py-2 bg-coral-500 text-white rounded-lg shadow-sm text-sm font-medium hover:bg-coral-600"
              >
                {id ? 'Spara ändringar' : 'Skapa artikel'}
              </motion.button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};