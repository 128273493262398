import React from 'react';
import { useParams } from 'react-router-dom';
import { Truck, Shield, Star, Clock, CheckCircle, Sparkles, Box, Home } from 'lucide-react';
import { SEO } from '../components/shared/SEO';
import { PageHero } from '../components/shared/PageHero';
import { RecoWidget } from '../components/ui/RecoWidget';
import { MovingBenefits } from '../components/moving/MovingBenefits';
import { MovingProcess } from '../components/moving/MovingProcess';
import { MovingTrust } from '../components/moving/MovingTrust';
import { ServiceGrid } from '../components/shared/ServiceGrid';
import { ServiceRequestForm } from '../components/shared/ServiceRequestForm';
import { getCityData } from '../data/cities';
import { TrustSection } from '../components/home/TrustSection';
import { HowItWorks } from '../components/home/HowItWorks';
import { BenefitsSection } from '../components/home/BenefitsSection';
import { CertificationsSection } from '../components/home/CertificationsSection';
import { FAQ } from '../components/shared/FAQ';
import { homeFAQ } from '../data/faqContent';

export const CityPage = () => {
  const { cityName = '' } = useParams();
  const cityData = getCityData(cityName);

  const stats = [
    { icon: Truck, text: "300+ flyttfirmor" },
    { icon: Shield, text: "Verifierade partners" },
    { icon: Star, text: "4.9/5 kundbetyg" }
  ];

  const handleCtaClick = () => {
    const formElement = document.getElementById('service-request-form');
    if (formElement) {
      const headerOffset = 100;
      const elementPosition = formElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const services = [
    {
      icon: Truck,
      title: 'Flytthjälp',
      description: cityData.services.moving,
      href: '/flytthjalp'
    },
    {
      icon: Sparkles,
      title: 'Flyttstädning',
      description: cityData.services.cleaning,
      href: '/flyttstadning'
    },
    {
      icon: Box,
      title: 'Magasinering',
      description: cityData.services.storage,
      href: '/magasinering'
    },
    {
      icon: Home,
      title: 'Dödsbo',
      description: cityData.services.estate,
      href: '/dodsbo'
    }
  ];

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title={cityData.title}
        description={cityData.metaDescription}
      />

      <PageHero
        title={`Flyttjänster i`}
        titleHighlight={cityName}
        description={cityData.description}
        stats={stats}
        ctaText="Få offerter nu"
        ctaAction={handleCtaClick}
      />

      <RecoWidget />
      
      <TrustSection />
      
      <ServiceRequestForm />
      
      <HowItWorks />
      
      <BenefitsSection />
      
      <CertificationsSection />
      
      <ServiceGrid />
      
      <FAQ 
        items={homeFAQ}
        title={`Vanliga frågor om flyttjänster i ${cityName}`}
      />
    </div>
  );
};