import React from 'react';
import { Search, MapPin, Filter, Shield } from 'lucide-react';
import { Company } from '../../lib/supabase';

interface CompanySearchFormProps {
  onSearch: (params: { query: string; service: string; city: string; verifiedOnly: boolean }) => void;
  companies: Company[];
  className?: string;
}

export const CompanySearchForm = ({ 
  onSearch, 
  companies, 
  className = '' 
}: CompanySearchFormProps) => {
  const [searchParams, setSearchParams] = React.useState({
    query: '',
    service: '',
    city: '',
    verifiedOnly: false
  });

  const services = [
    'Flytthjälp',
    'Kontorsflytt',
    'Utlandsflytt',
    'Flyttstädning',
    'Magasinering',
    'Dödsbo'
  ];

  // Debounce search updates
  React.useEffect(() => {
    const timer = setTimeout(() => {
      onSearch(searchParams);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchParams, onSearch]);

  const handleChange = (field: string, value: string | boolean) => {
    setSearchParams(prev => ({ ...prev, [field]: value }));
  };

  return (
    <div className={`space-y-8 ${className}`}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Company Search */}
        <div className="relative">
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Sök företag..."
            value={searchParams.query}
            onChange={(e) => handleChange('query', e.target.value)}
            className="w-full pl-12 pr-4 py-3.5 rounded-xl bg-gray-50/80 border-none focus:ring-2 focus:ring-coral-500 text-gray-700 placeholder-gray-400 shadow-sm"
          />
        </div>

        {/* City Input */}
        <div className="relative">
          <MapPin className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Sök stad..."
            value={searchParams.city}
            onChange={(e) => handleChange('city', e.target.value)}
            className="w-full pl-12 pr-4 py-3.5 rounded-xl bg-gray-50/80 border-none focus:ring-2 focus:ring-coral-500 text-gray-700 placeholder-gray-400 shadow-sm"
          />
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-6">
        {/* Service Select */}
        <div className="relative flex-1">
          <Filter className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <select
            value={searchParams.service}
            onChange={(e) => handleChange('service', e.target.value)}
            className="w-full pl-12 pr-4 py-3.5 rounded-xl bg-gray-50/80 border-none focus:ring-2 focus:ring-coral-500 text-gray-700 shadow-sm appearance-none"
          >
            <option value="">Alla tjänster</option>
            {services.map(service => (
              <option key={service} value={service}>{service}</option>
            ))}
          </select>
        </div>

        {/* Verified Only Checkbox */}
        <label className="flex items-center space-x-3 cursor-pointer group py-2">
          <input
            type="checkbox"
            checked={searchParams.verifiedOnly}
            onChange={(e) => handleChange('verifiedOnly', e.target.checked)}
            className="form-checkbox h-5 w-5 text-coral-500 rounded border-gray-300 focus:ring-coral-500"
          />
          <span className="flex items-center text-gray-700 group-hover:text-coral-500 transition-colors">
            <Shield className="w-4 h-4 mr-2" />
            Visa endast verifierade företag
          </span>
        </label>
      </div>
    </div>
  );
};