import React from 'react';
import { LucideIcon } from 'lucide-react';

interface PageHeroProps {
  title: string;
  titleHighlight?: string;
  description: string;
  stats?: Array<{
    icon: LucideIcon;
    text: string;
  }>;
  ctaText?: string;
  ctaAction?: () => void;
}

export const PageHero = ({
  title,
  titleHighlight,
  description,
  stats,
  ctaText = "Få offerter nu",
  ctaAction
}: PageHeroProps) => {
  const handleCtaClick = () => {
    if (ctaAction) {
      ctaAction();
      return;
    }

    const formElement = document.getElementById('service-request-form');
    if (formElement) {
      const headerOffset = 100;
      const elementPosition = formElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <section className="relative min-h-[90vh] sm:min-h-screen flex items-center bg-gradient-hero overflow-hidden pt-32 md:pt-40 pb-32">
      {/* Background Elements - Lower z-index */}
      <div className="absolute inset-0 z-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-coral-50/70 via-transparent to-transparent" />
        <div className="absolute inset-0 bg-[linear-gradient(160deg,_var(--tw-gradient-stops))] from-white via-transparent to-transparent opacity-70" />
      </div>

      {/* Content - Higher z-index */}
      <div className="relative z-10 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="text-center space-y-6 sm:space-y-8">
            <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-playfair font-bold leading-tight">
              {title}{' '}
              {titleHighlight && (
                <span className="text-coral-500">{titleHighlight}</span>
              )}
            </h1>
            
            <p className="text-lg sm:text-xl md:text-2xl text-gray-600 max-w-3xl mx-auto leading-relaxed">
              {description}
            </p>

            <div className="flex justify-center relative z-20">
              <button
                onClick={handleCtaClick}
                className="btn-primary group inline-flex items-center justify-center space-x-2 px-8 py-4 min-w-[200px] relative"
              >
                <span>{ctaText}</span>
                <span className="inline-block transform group-hover:translate-x-1 transition-transform">→</span>
              </button>
            </div>

            {stats && (
              <div className="flex flex-col sm:flex-row items-center justify-center gap-8 mt-12">
                {stats.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center space-y-3"
                  >
                    <div className="w-12 h-12 bg-coral-50 rounded-xl flex items-center justify-center">
                      <item.icon className="w-6 h-6 text-coral-500" />
                    </div>
                    <span className="text-base md:text-lg text-gray-700 font-medium text-center">
                      {item.text}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};