import React from 'react';
import { Search, Filter } from 'lucide-react';

interface ArticlesSearchProps {
  onSearch: (term: string) => void;
  onCategoryChange: (category: string) => void;
  selectedCategory: string;
}

export const ArticlesSearch = ({ 
  onSearch, 
  onCategoryChange,
  selectedCategory 
}: ArticlesSearchProps) => {
  const categories = [
    'Flytthjälp',
    'Kontorsflytt',
    'Utlandsflytt',
    'Flyttstädning',
    'Magasinering',
    'Dödsbo',
    'Guide'
  ];

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Search Input */}
        <div className="relative">
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Sök artiklar..."
            onChange={(e) => onSearch(e.target.value)}
            className="w-full pl-12 pr-4 py-3.5 rounded-xl bg-gray-50/80 border-none focus:ring-2 focus:ring-coral-500 text-gray-700 placeholder-gray-400 shadow-sm"
          />
        </div>

        {/* Category Select */}
        <div className="relative">
          <Filter className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <select
            value={selectedCategory}
            onChange={(e) => onCategoryChange(e.target.value)}
            className="w-full pl-12 pr-4 py-3.5 rounded-xl bg-gray-50/80 border-none focus:ring-2 focus:ring-coral-500 text-gray-700 shadow-sm appearance-none"
          >
            <option value="">Alla kategorier</option>
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};