import { useState, useEffect } from 'react';
import { Article } from '../lib/supabase';
import { fetchArticleById } from '../lib/api/articles';
import toast from 'react-hot-toast';

export const useArticleById = (id?: string) => {
  const [article, setArticle] = useState<Article | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getArticle = async () => {
      if (!id) {
        setError('No ID provided');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);
        const data = await fetchArticleById(id);
        setArticle(data);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load article';
        setError(errorMessage);
        toast.error(errorMessage);
        setArticle(null);
      } finally {
        setIsLoading(false);
      }
    };

    getArticle();
  }, [id]);

  return { article, isLoading, error };
};