import React from 'react';
import { SEO } from '../components/shared/SEO';
import { ArticleList } from '../components/articles/ArticleList';
import { ArticlesSearch } from '../components/articles/ArticlesSearch';
import { useArticles } from '../hooks/useArticles';

export const Articles = () => {
  const { articles, isLoading, error } = useArticles();
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedCategory, setSelectedCategory] = React.useState('');

  const filteredArticles = React.useMemo(() => {
    return articles.filter(article => {
      const matchesSearch = article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          article.content.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesCategory = !selectedCategory || article.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });
  }, [articles, searchTerm, selectedCategory]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <SEO 
        title="Artiklar och guider"
        description="Hitta svar på dina frågor och få värdefull information om flyttjänster och relaterade ämnen."
      />
      
      <section className="pt-32 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mb-16">
            <h1 className="text-4xl md:text-5xl font-playfair font-bold text-gray-900 mb-6">
              Artiklar och guider
            </h1>
            <p className="text-lg text-gray-600 leading-relaxed">
              Hitta svar på dina frågor och få värdefull information om flyttjänster och relaterade ämnen.
            </p>
          </div>

          <div className="bg-white rounded-2xl shadow-premium p-8 mb-16">
            <ArticlesSearch 
              onSearch={setSearchTerm}
              onCategoryChange={setSelectedCategory}
              selectedCategory={selectedCategory}
            />
          </div>

          <div>
            {isLoading ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {[...Array(6)].map((_, index) => (
                  <div
                    key={index}
                    className="bg-white rounded-xl p-8 shadow-sm animate-pulse"
                  >
                    <div className="h-6 w-24 bg-gray-200 rounded mb-4" />
                    <div className="h-8 w-3/4 bg-gray-200 rounded mb-6" />
                    <div className="space-y-3">
                      <div className="h-4 w-full bg-gray-200 rounded" />
                      <div className="h-4 w-full bg-gray-200 rounded" />
                      <div className="h-4 w-2/3 bg-gray-200 rounded" />
                    </div>
                  </div>
                ))}
              </div>
            ) : error ? (
              <div className="bg-white rounded-xl p-8 text-center shadow-sm">
                <p className="text-gray-600">
                  Ett fel uppstod när artiklarna skulle hämtas. Försök igen senare.
                </p>
              </div>
            ) : filteredArticles.length > 0 ? (
              <ArticleList articles={filteredArticles} />
            ) : (
              <div className="bg-white rounded-xl p-8 text-center shadow-sm">
                <p className="text-gray-600">
                  Inga artiklar hittades som matchar din sökning.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};