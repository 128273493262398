import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';

interface AdminBackButtonProps {
  to?: string;
}

export const AdminBackButton = ({ to = '/admin' }: AdminBackButtonProps) => {
  const navigate = useNavigate();

  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => navigate(to)}
      className="inline-flex items-center space-x-2 text-gray-600 hover:text-coral-500 transition-colors px-4 py-2 rounded-lg hover:bg-white/50"
    >
      <ArrowLeft className="w-5 h-5" />
      <span>Tillbaka</span>
    </motion.button>
  );
};