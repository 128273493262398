import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Users, CheckCircle } from 'lucide-react';

const cities = [
  { name: 'Kiruna', x: '50%', y: '5%' },
  { name: 'Luleå', x: '55%', y: '15%' },
  { name: 'Umeå', x: '52%', y: '25%' },
  { name: 'Östersund', x: '45%', y: '35%' },
  { name: 'Sundsvall', x: '52%', y: '42%' },
  { name: 'Gävle', x: '51%', y: '50%' },
  { name: 'Uppsala', x: '53%', y: '55%' },
  { name: 'Stockholm', x: '55%', y: '58%' },
  { name: 'Örebro', x: '48%', y: '60%' },
  { name: 'Linköping', x: '51%', y: '65%' },
  { name: 'Göteborg', x: '40%', y: '70%' },
  { name: 'Jönköping', x: '46%', y: '68%' },
  { name: 'Växjö', x: '45%', y: '75%' },
  { name: 'Kalmar', x: '50%', y: '78%' },
  { name: 'Halmstad', x: '42%', y: '80%' },
  { name: 'Malmö', x: '43%', y: '90%' }
];

const features = [
  {
    icon: Shield,
    title: 'Kvalitetssäkrade partners',
    description: 'Alla våra partners genomgår noggrann granskning'
  },
  {
    icon: CheckCircle,
    title: 'Verifierade flyttfirmor',
    description: 'Vi samarbetar endast med licensierade och försäkrade företag'
  },
  {
    icon: Users,
    title: 'Personlig service',
    description: 'Vi matchar dig med flyttfirmor som passar dina specifika behov'
  }
];

export const CoverageSection = () => {
  return (
    <section className="py-32 bg-gradient-to-b from-white to-coral-50/10 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-8"
          >
            <div>
              <motion.h2 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                className="text-4xl md:text-5xl font-playfair font-bold mb-6"
              >
                Hitta en flyttfirma du kan{' '}
                <span className="text-coral-500">lita på</span>
              </motion.h2>
              <motion.p 
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 }}
                className="text-gray-600 text-lg leading-relaxed"
              >
                Oavsett var i Sverige du befinner dig, hjälper Flytti.se dig att hitta pålitliga 
                flyttfirmor nära dig. Vi samarbetar enbart med seriösa och noggrant granskade 
                flyttfirmor som hanterar dina ägodelar med största omsorg.
              </motion.p>
            </div>

            <div className="space-y-6">
              {features.map((feature, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ 
                    duration: 0.5,
                    delay: index * 0.1,
                    ease: [0.21, 0.45, 0.27, 0.9]
                  }}
                  className="flex items-start space-x-4 group"
                >
                  <div className="relative">
                    <div className="w-12 h-12 bg-coral-50 rounded-xl flex items-center justify-center transition-transform duration-500 group-hover:scale-110">
                      <feature.icon className="w-6 h-6 text-coral-500 transition-transform duration-500 group-hover:scale-110" />
                    </div>
                    <div className="absolute inset-0 bg-coral-200/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                  </div>
                  <div className="flex-1">
                    <h3 className="font-semibold text-lg mb-1 group-hover:text-coral-500 transition-colors duration-300">
                      {feature.title}
                    </h3>
                    <p className="text-gray-600 leading-relaxed">
                      {feature.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="relative aspect-[3/4] bg-gradient-to-br from-coral-50/80 to-white rounded-3xl overflow-hidden shadow-premium"
          >
            <div className="absolute inset-0">
              {cities.map((city, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ 
                    duration: 0.5,
                    delay: index * 0.05,
                    ease: [0.21, 0.45, 0.27, 0.9]
                  }}
                  className="absolute"
                  style={{ left: city.x, top: city.y }}
                >
                  <div className="relative group">
                    {/* City Label */}
                    <div className="absolute -top-6 left-1/2 -translate-x-1/2 whitespace-nowrap">
                      <span className="text-xs font-medium text-gray-600">
                        {city.name}
                      </span>
                    </div>
                    
                    {/* Pulsing Dot */}
                    <div className="relative w-1.5 h-1.5">
                      <div className="absolute inset-0 bg-coral-500 rounded-full animate-pulse-dot" />
                      <div className="absolute inset-0 bg-coral-500 rounded-full">
                        <div className="absolute inset-[-3px] bg-coral-500/20 rounded-full animate-ping" />
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};