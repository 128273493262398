import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { ErrorBoundary } from './components/shared/ErrorBoundary';
import { ScrollToTop } from './components/shared/ScrollToTop';
import { AppRoutes } from './routes';
import { Navbar } from './components/layout/Navbar';
import { Footer } from './components/layout/Footer';
import { PageLayout } from './components/shared/PageLayout';
import { FileSystemProvider } from './components/shared/FileSystemProvider';
import { CookieBanner } from './components/shared/CookieBanner';

const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-coral-500" />
  </div>
);

const App = () => {
  return (
    <ErrorBoundary>
      <HelmetProvider>
        <BrowserRouter>
          <FileSystemProvider>
            <ScrollToTop />
            <PageLayout>
              <Navbar />
              <main className="min-h-screen">
                <Suspense fallback={<LoadingSpinner />}>
                  <AppRoutes />
                </Suspense>
              </main>
              <Footer />
            </PageLayout>
            <Toaster 
              position="top-center"
              toastOptions={{
                duration: 5000,
                style: {
                  background: '#333',
                  color: '#fff',
                },
              }} 
            />
            <CookieBanner />
          </FileSystemProvider>
        </BrowserRouter>
      </HelmetProvider>
    </ErrorBoundary>
  );
};

export default App;