import React from 'react';
import { motion } from 'framer-motion';
import { MessageSquare, FileText, CheckCircle } from 'lucide-react';

const steps = [
  {
    icon: MessageSquare,
    title: 'Beskriv dina behov',
    description: 'Fyll i vårt formulär med information om din flyttstädning.'
  },
  {
    icon: FileText,
    title: 'Få skräddarsydda offerter',
    description: 'Specialiserade städfirmor kontaktar dig med anpassade lösningar.'
  },
  {
    icon: CheckCircle,
    title: 'Välj bästa alternativet',
    description: 'Jämför offerter och välj den partner som bäst möter dina behov.'
  }
];

export const CleaningProcess = () => {
  return (
    <section className="relative py-32 bg-section-dark">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h2 className="text-4xl md:text-5xl font-playfair font-bold mb-6">
            Så fungerar{' '}
            <span className="relative inline-block">
              <span className="relative z-10 text-coral-500">flyttstädning</span>
              <span className="absolute bottom-2 left-0 h-3 w-full bg-gradient-to-r from-coral-100 to-coral-200 -z-10" />
            </span>
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            En smidig process för att hitta rätt städfirma
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: [0.21, 0.45, 0.27, 0.9]
              }}
              className="group relative h-full"
            >
              <div className="relative bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500 h-full flex flex-col">
                <div className="relative mb-6 flex-shrink-0">
                  <div className="w-16 h-16 bg-coral-50 rounded-2xl flex items-center justify-center relative overflow-hidden">
                    <step.icon className="w-8 h-8 text-coral-500 relative z-10 transform group-hover:scale-110 transition-transform duration-500" />
                  </div>
                </div>

                <div className="flex-grow flex flex-col">
                  <h3 className="text-2xl font-playfair font-semibold mb-4 group-hover:text-coral-500 transition-colors duration-300">
                    {step.title}
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    {step.description}
                  </p>
                </div>

                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};