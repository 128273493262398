import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Truck, Menu, ChevronDown, 
  Search, Building2, Globe, Sparkles, 
  Box, Home, LogIn, MessageSquare,
  FileText, MapPin
} from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { MobileMenu } from './MobileMenu';
import type { NavLink } from '../../types/navigation';

export const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isScrolled, setIsScrolled] = React.useState(false);
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const isAdminPage = location.pathname.startsWith('/admin');

  React.useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  React.useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const mainLinks: NavLink[] = [
    { name: 'Hem', href: '/', icon: Home },
    { name: 'Flytthjälp', href: '/flytthjalp', icon: Truck },
    { name: 'Kontorsflytt', href: '/kontorsflytt', icon: Building2 },
    { name: 'Utlandsflytt', href: '/utlandsflytt', icon: Globe },
    { name: 'Flyttstädning', href: '/flyttstadning', icon: Sparkles },
    { name: 'Dödsbo', href: '/dodsbo', icon: Home }
  ];

  const dropdownLinks: NavLink[] = [
    { name: 'Magasinering', href: '/magasinering', icon: Box },
    { name: 'Sök företag', href: '/foretag', icon: Search },
    { name: 'Artiklar', href: '/artiklar', icon: FileText },
    { name: 'Sök stad', href: '/stader', icon: MapPin },
    { name: 'Anslut företag', href: '/anslut-foretag', icon: Building2 }
  ];

  return (
    <nav className="fixed w-full z-50">
      <div className={`w-full transition-colors ${
        isScrolled || isOpen ? 'bg-white/95 backdrop-blur-sm shadow-sm' : 'bg-transparent'
      }`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-24">
            {/* Logo */}
            <Link 
              to={isAdminPage ? '/admin' : '/'} 
              className="group flex items-center space-x-3 transition-transform duration-300 hover:-translate-y-0.5"
            >
              <div className="relative">
                <div className="absolute inset-0 bg-coral-200/20 rounded-lg blur-lg transform group-hover:scale-110 transition-transform duration-300" />
                <div className="relative bg-gradient-to-br from-coral-500 to-coral-600 p-2 rounded-lg shadow-lg">
                  <Truck className="h-5 w-5 md:h-6 md:w-6 text-white transform group-hover:rotate-12 transition-transform duration-300" />
                </div>
              </div>
              <span className="text-xl md:text-2xl font-playfair font-bold bg-gradient-to-r from-coral-600 to-coral-500 bg-clip-text text-transparent">
                Flytti.se
              </span>
            </Link>

            {/* Desktop Navigation */}
            {!isAdminPage && (
              <div className="hidden lg:flex items-center space-x-8">
                {mainLinks.map((link) => (
                  <Link
                    key={link.name}
                    to={link.href}
                    className={`group flex items-center space-x-2 text-gray-600 hover:text-coral-500 transition-colors duration-300 ${
                      location.pathname === link.href ? 'text-coral-500' : ''
                    }`}
                  >
                    <span className="relative">
                      {link.name}
                      <span className={`absolute inset-x-0 bottom-0 h-0.5 bg-coral-500 transform transition-transform duration-300 ${
                        location.pathname === link.href ? 'scale-x-100' : 'scale-x-0 group-hover:scale-x-100'
                      }`} />
                    </span>
                  </Link>
                ))}

                {/* Dropdown Menu */}
                <div className="relative group">
                  <button className="flex items-center space-x-1 text-gray-600 hover:text-coral-500 transition-colors duration-300">
                    <span>Mer</span>
                    <ChevronDown className="w-4 h-4" />
                  </button>
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
                    {dropdownLinks.map((link) => (
                      <Link
                        key={link.name}
                        to={link.href}
                        className="flex items-center space-x-2 px-4 py-2 text-gray-600 hover:text-coral-500 hover:bg-gray-50 transition-colors duration-300"
                      >
                        <link.icon className="w-4 h-4" />
                        <span>{link.name}</span>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {/* Desktop Action Buttons */}
            {!isAdminPage && (
              <div className="hidden lg:flex items-center space-x-6">
                <Link
                  to="/fa-offert"
                  className="inline-flex items-center px-6 py-2.5 border border-transparent rounded-lg text-sm font-medium text-white bg-coral-500 hover:bg-coral-600 transition-colors duration-300"
                >
                  <MessageSquare className="w-4 h-4 mr-2" />
                  Få offerter
                </Link>
                <Link
                  to={isAuthenticated ? '/admin' : '/admin/login'}
                  className="inline-flex items-center px-6 py-2.5 border border-transparent rounded-lg text-sm font-medium text-white bg-coral-500 hover:bg-coral-600 transition-colors duration-300"
                >
                  <LogIn className="w-4 h-4 mr-2" />
                  {isAuthenticated ? 'Admin' : 'Logga in'}
                </Link>
              </div>
            )}

            {/* Mobile Menu Button */}
            {!isAdminPage && (
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="lg:hidden relative w-10 h-10 flex items-center justify-center"
                aria-label={isOpen ? 'Stäng meny' : 'Öppna meny'}
              >
                <Menu className="w-6 h-6 text-gray-600" />
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        mainLinks={mainLinks}
        dropdownLinks={dropdownLinks}
        isAuthenticated={isAuthenticated}
      />
    </nav>
  );
};