import React from 'react';
import { motion } from 'framer-motion';

export const TestComponent = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-coral-50 to-white p-4">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-lg w-full bg-white rounded-xl shadow-premium p-8"
      >
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          File System Verification
        </h2>
        <div className="space-y-4">
          <div className="p-4 bg-green-50 rounded-lg">
            <p className="text-green-700">
              ✓ Component updates working
            </p>
          </div>
          <div className="p-4 bg-coral-50 rounded-lg">
            <p className="text-coral-700">
              ✓ File system changes detected
            </p>
          </div>
          <p className="text-gray-600">
            This component confirms that file system changes are being properly synchronized with the development server.
          </p>
        </div>
      </motion.div>
    </div>
  );
};