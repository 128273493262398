import { create } from 'zustand';

interface FileSystemStore {
  lastUpdate: number;
  updateTimestamp: () => void;
}

export const useFileSystem = create<FileSystemStore>((set) => ({
  lastUpdate: Date.now(),
  updateTimestamp: () => set({ lastUpdate: Date.now() })
}));

export const triggerFileUpdate = () => {
  useFileSystem.getState().updateTimestamp();
};