import { useState, useEffect } from 'react';
import { Article } from '../lib/supabase';
import { fetchArticleBySlug } from '../lib/api/articles';
import toast from 'react-hot-toast';

export const useArticle = (slug?: string) => {
  const [article, setArticle] = useState<Article | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getArticle = async () => {
      if (!slug) {
        setError('No slug provided');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        setError(null);
        const data = await fetchArticleBySlug(slug);
        setArticle(data);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load article';
        setError(errorMessage);
        toast.error('Kunde inte hämta artikeln');
        setArticle(null);
      } finally {
        setIsLoading(false);
      }
    };

    getArticle();
  }, [slug]);

  return { article, isLoading, error };
};