import React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { MapPin, Search } from 'lucide-react';
import { cities } from '../data/cities';
import { SEO } from '../components/shared/SEO';

export const Cities = () => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [expandedRegions, setExpandedRegions] = React.useState<string[]>([]);

  const toggleRegion = (region: string) => {
    setExpandedRegions(prev => 
      prev.includes(region) 
        ? prev.filter(r => r !== region)
        : [...prev, region]
    );
  };

  const filteredCities = React.useMemo(() => {
    const search = searchTerm.toLowerCase();
    const result: Record<string, string[]> = {};

    Object.entries(cities).forEach(([region, data]) => {
      const filteredCities = data.cities.filter(city => 
        city.toLowerCase().includes(search)
      );
      if (filteredCities.length > 0) {
        result[region] = filteredCities;
      }
    });

    return result;
  }, [searchTerm]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.05,
        delayChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-coral-50/10">
      <SEO 
        title="Hitta flyttjänster i din stad"
        description="Vi hjälper dig hitta kvalitetssäkrade flyttfirmor och relaterade tjänster i hela Sverige. Välj din stad för lokalt anpassad information."
      />

      <section className="pt-32 pb-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-3xl mb-12"
          >
            <h1 className="text-4xl md:text-5xl font-playfair font-bold mb-6">
              Hitta tjänster i din stad
            </h1>
            <p className="text-lg text-gray-600">
              Vi hjälper dig att hitta kvalitetssäkrade flyttfirmor och relaterade tjänster i hela Sverige
            </p>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="mb-12"
          >
            <div className="relative max-w-2xl">
              <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
              <input
                type="text"
                placeholder="Sök efter region eller stad..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-12 pr-4 py-3 rounded-xl bg-white shadow-sm border-none focus:ring-2 focus:ring-coral-500"
              />
            </div>
          </motion.div>

          <motion.div 
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="space-y-4"
          >
            {Object.entries(filteredCities).map(([region, citiesList]) => (
              <motion.div
                key={region}
                variants={itemVariants}
                className="bg-white rounded-xl shadow-sm overflow-hidden"
              >
                <button
                  onClick={() => toggleRegion(region)}
                  className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50 transition-colors"
                >
                  <div className="flex items-center space-x-2">
                    <MapPin className="w-5 h-5 text-coral-500" />
                    <span className="text-lg font-semibold">
                      {cities[region].name}
                    </span>
                    <span className="text-sm text-gray-500">
                      ({citiesList.length} städer)
                    </span>
                  </div>
                  <motion.svg
                    className="w-5 h-5"
                    animate={{ rotate: expandedRegions.includes(region) ? 180 : 0 }}
                    transition={{ duration: 0.2 }}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </motion.svg>
                </button>

                <AnimatePresence>
                  {expandedRegions.includes(region) && (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="overflow-hidden"
                    >
                      <div className="px-6 py-4 bg-gray-50 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {citiesList.map((city) => (
                          <Link
                            key={city}
                            to={`/stader/${city.toLowerCase()}`}
                            className="flex items-center space-x-2 text-gray-600 hover:text-coral-500 transition-colors"
                          >
                            <MapPin className="w-4 h-4" />
                            <span>{city}</span>
                          </Link>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>
    </div>
  );
};