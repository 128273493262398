import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { MovingHelp } from './pages/MovingHelp';
import { OfficeMoveHelp } from './pages/OfficeMoveHelp';
import { InternationalMoveHelp } from './pages/InternationalMoveHelp';
import { CleaningHelp } from './pages/CleaningHelp';
import { StorageHelp } from './pages/StorageHelp';
import { EstateHelp } from './pages/EstateHelp';
import { CompanySearch } from './pages/CompanySearch';
import { CompanyDetails } from './pages/CompanyDetails';
import { Articles } from './pages/Articles';
import { ArticleDetail } from './pages/ArticleDetail';
import { Cities } from './pages/Cities';
import { CityPage } from './pages/CityPage';
import { GetQuote } from './pages/GetQuote';
import { RegisterCompany } from './pages/RegisterCompany';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService';
import { CookiePolicy } from './pages/CookiePolicy';
import { AdminLayout } from './components/admin/AdminLayout';
import { AdminDashboard } from './pages/admin/AdminDashboard';
import { AdminCompanies } from './pages/admin/AdminCompanies';
import { AdminArticles } from './pages/admin/AdminArticles';
import { CreateCompany } from './pages/admin/CreateCompany';
import { EditCompany } from './pages/admin/EditCompany';
import { EditArticle } from './pages/admin/EditArticle';
import { AdminLogin } from './pages/admin/AdminLogin';
import { AdminRoute } from './components/admin/AdminRoute';
import { TestComponent } from './components/shared/TestComponent';

export const AppRoutes = () => {
  return (
    <Routes>
      {/* Test Route */}
      <Route path="/test" element={<TestComponent />} />

      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/flytthjalp" element={<MovingHelp />} />
      <Route path="/kontorsflytt" element={<OfficeMoveHelp />} />
      <Route path="/utlandsflytt" element={<InternationalMoveHelp />} />
      <Route path="/flyttstadning" element={<CleaningHelp />} />
      <Route path="/magasinering" element={<StorageHelp />} />
      <Route path="/dodsbo" element={<EstateHelp />} />
      <Route path="/fa-offert" element={<GetQuote />} />
      <Route path="/anslut-foretag" element={<RegisterCompany />} />
      <Route path="/foretag" element={<CompanySearch />} />
      <Route path="/foretag/:id" element={<CompanyDetails />} />
      <Route path="/artiklar" element={<Articles />} />
      <Route path="/artiklar/:slug" element={<ArticleDetail />} />
      <Route path="/stader" element={<Cities />} />
      <Route path="/stader/:cityName" element={<CityPage />} />
      <Route path="/dataskyddspolicy" element={<PrivacyPolicy />} />
      <Route path="/anvandarvillkor" element={<TermsOfService />} />
      <Route path="/cookies" element={<CookiePolicy />} />
      
      {/* Admin Routes */}
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/admin" element={<AdminRoute><AdminLayout /></AdminRoute>}>
        <Route index element={<AdminDashboard />} />
        <Route path="companies" element={<AdminCompanies />} />
        <Route path="companies/new" element={<CreateCompany />} />
        <Route path="companies/:id/edit" element={<EditCompany />} />
        <Route path="articles" element={<AdminArticles />} />
        <Route path="articles/new" element={<EditArticle />} />
        <Route path="articles/:id/edit" element={<EditArticle />} />
      </Route>
    </Routes>
  );
};