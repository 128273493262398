import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Clock, PiggyBank } from 'lucide-react';

const benefits = [
  {
    icon: Shield,
    title: 'Tryggt',
    description: 'Vi kvalitetssäkrar att alla företag som är anslutna till oss har bra omdöme, har rätt tillstånd och är försäkrade.'
  },
  {
    icon: Clock,
    title: 'Enkelt',
    description: 'Spara tid genom att jämföra flera företag samtidigt. Vi hjälper dig hitta rätt företag för dina behov.'
  },
  {
    icon: PiggyBank,
    title: 'Spara pengar',
    description: 'Genom att enkelt jämföra flera företag och tjänster kan du fatta bättre och snabbare beslut, få kunskap och spara pengar.'
  }
];

export const BenefitsSection = () => {
  return (
    <section className="py-32 bg-gradient-to-b from-white to-coral-50/20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
          {benefits.map((benefit, index) => (
            <BenefitCard key={index} {...benefit} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

const BenefitCard = ({ icon: Icon, title, description, index }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ 
        duration: 0.7,
        delay: index * 0.2,
        ease: [0.21, 0.45, 0.27, 0.9]
      }}
      className="group text-center"
    >
      <div className="relative mb-8 mx-auto">
        {/* Icon Background with Glow Effect */}
        <div className="w-24 h-24 mx-auto relative">
          <div className="absolute inset-0 bg-coral-100 rounded-[32px] transform rotate-45 group-hover:rotate-[30deg] transition-transform duration-700" />
          <div className="absolute inset-0 bg-gradient-to-br from-coral-50 to-white rounded-[32px] transform rotate-45 group-hover:rotate-[60deg] transition-transform duration-700 opacity-80" />
          
          {/* Main Icon Container */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-16 h-16 bg-white/80 rounded-2xl backdrop-blur-sm flex items-center justify-center transform group-hover:scale-110 transition-transform duration-500">
              <Icon className="w-8 h-8 text-coral-500 transform group-hover:scale-110 transition-transform duration-500" />
            </div>
          </div>
          
          {/* Animated Glow Effect */}
          <div className="absolute inset-0 bg-coral-200/20 rounded-[32px] blur-xl group-hover:blur-2xl transform group-hover:scale-110 transition-all duration-700" />
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ 
          duration: 0.5,
          delay: index * 0.2 + 0.3,
          ease: "easeOut"
        }}
      >
        <h3 className="text-2xl font-playfair font-semibold mb-4 text-gray-900">
          {title}
        </h3>
        <p className="text-gray-600 leading-relaxed max-w-sm mx-auto">
          {description}
        </p>
      </motion.div>

      {/* Hover Line Effect */}
      <div className="relative mt-6 mx-auto w-12 overflow-hidden">
        <div className="h-0.5 w-full bg-coral-200/50 rounded" />
        <div className="absolute inset-0 h-0.5 bg-coral-500 rounded transform -translate-x-full group-hover:translate-x-0 transition-transform duration-700" />
      </div>
    </motion.div>
  );
};