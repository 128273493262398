import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Calendar, FileText } from 'lucide-react';
import { format } from 'date-fns';
import { Article } from '../lib/supabase';
import { SEO } from '../components/shared/SEO';
import { useArticle } from '../hooks/useArticle';
import { ArticleContent } from '../components/articles/ArticleContent';

export const ArticleDetail = () => {
  const { slug } = useParams();
  const { article, isLoading, error } = useArticle(slug);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-coral-500" />
      </div>
    );
  }

  if (error || !article) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
            Artikeln kunde inte hittas
          </h2>
          <Link
            to="/artiklar"
            className="inline-flex items-center text-coral-500 hover:text-coral-600 font-medium"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Tillbaka till artiklar
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <SEO 
        title={article.title}
        description={article.content.substring(0, 155)}
      />
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
        <Link
          to="/artiklar"
          className="inline-flex items-center text-coral-500 hover:text-coral-600 mb-8 group font-medium"
        >
          <ArrowLeft className="w-5 h-5 mr-2 transform group-hover:-translate-x-1 transition-transform" />
          Tillbaka till artiklar
        </Link>

        <motion.article
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-2xl shadow-premium overflow-hidden"
        >
          {/* Article Header */}
          <div className="p-8 md:p-12 border-b border-gray-100">
            <div className="flex items-center gap-4 mb-6">
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-coral-50 text-coral-700">
                <FileText className="w-4 h-4 mr-2" />
                {article.category}
              </span>
              <time className="flex items-center text-sm text-gray-500">
                <Calendar className="w-4 h-4 mr-2" />
                {format(new Date(article.created_at), 'yyyy-MM-dd')}
              </time>
            </div>

            <h1 className="text-3xl sm:text-4xl md:text-5xl font-playfair font-bold text-gray-900 mb-6 leading-tight">
              {article.title}
            </h1>
          </div>

          {/* Article Content */}
          <div className="p-8 md:p-12">
            <div className="max-w-[65ch] mx-auto">
              <ArticleContent content={article.content} />
            </div>
          </div>
        </motion.article>
      </div>
    </div>
  );
};