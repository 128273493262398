import React from 'react';
import { Link } from 'react-router-dom';
import { Building2, MapPin, Mail, Phone, Shield, CheckCircle } from 'lucide-react';
import { Company } from '../../lib/supabase';

interface CompanyListProps {
  companies: Company[];
}

export const CompanyList = React.memo(({ companies }: CompanyListProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {companies.map((company) => (
        <Link
          key={company.id}
          to={`/foretag/${company.id}`}
          className="group h-full"
        >
          <div className="h-full bg-white rounded-xl p-6 shadow-sm hover:shadow-lg transition-all duration-300">
            {/* Company Header */}
            <div className="flex items-start gap-4 mb-4">
              <div className="w-12 h-12 bg-coral-50 rounded-lg flex items-center justify-center flex-shrink-0">
                <Building2 className="w-6 h-6 text-coral-500" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900 group-hover:text-coral-500 transition-colors">
                  {company.name}
                </h3>
                <div className="flex items-center text-gray-600 mt-1">
                  <MapPin className="w-4 h-4 mr-1" />
                  {company.city}
                </div>
              </div>
            </div>

            {/* Verification Badge */}
            {company.is_verified && (
              <div className="mb-4 p-3 bg-green-50 rounded-lg border border-green-100">
                <div className="flex items-center gap-2">
                  <Shield className="w-4 h-4 text-green-600" />
                  <span className="text-sm font-medium text-green-700">
                    Verifierat företag
                  </span>
                </div>
              </div>
            )}

            {/* Services */}
            <div className="mb-4">
              <div className="flex flex-wrap gap-2">
                {company.services.slice(0, 3).map((service) => (
                  <span
                    key={service}
                    className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-coral-50 text-coral-700"
                  >
                    <CheckCircle className="w-3 h-3 mr-1" />
                    {service}
                  </span>
                ))}
                {company.services.length > 3 && (
                  <span className="text-xs text-gray-500">
                    +{company.services.length - 3} till
                  </span>
                )}
              </div>
            </div>

            {/* Contact Info */}
            <div className="pt-4 border-t border-gray-100">
              <div className="space-y-2">
                {company.phone && (
                  <div className="flex items-center text-gray-600">
                    <Phone className="w-4 h-4 mr-2" />
                    {company.phone}
                  </div>
                )}
                {company.email && (
                  <div className="flex items-center text-gray-600">
                    <Mail className="w-4 h-4 mr-2" />
                    {company.email}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
});

CompanyList.displayName = 'CompanyList';