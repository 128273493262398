import React from 'react';
import { motion } from 'framer-motion';
import { X, MapPin, Mail, Globe, Phone, Shield, CheckCircle, Building2 } from 'lucide-react';
import { Company } from '../../lib/supabase';

interface CompanyDetailsProps {
  company: Company;
  onClose: () => void;
}

export const CompanyDetails = ({ company, onClose }: CompanyDetailsProps) => {
  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center p-4 z-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.95, y: 20 }}
        animate={{ opacity: 1, scale: 1, y: 0 }}
        exit={{ opacity: 0, scale: 0.95, y: 20 }}
        transition={{ type: "spring", duration: 0.5 }}
        className="bg-white rounded-2xl shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto"
      >
        {/* Header */}
        <div className="sticky top-0 bg-white border-b border-gray-100 p-6 flex justify-between items-center z-10">
          <div className="flex items-center gap-4">
            <div className="w-12 h-12 bg-coral-50 rounded-xl flex items-center justify-center">
              <Building2 className="w-6 h-6 text-coral-500" />
            </div>
            <div>
              <h2 className="text-2xl font-playfair font-bold text-gray-900">
                {company.name}
              </h2>
              <div className="flex items-center text-gray-600">
                <MapPin className="w-4 h-4 mr-1" />
                {company.city}
              </div>
            </div>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 p-2 hover:bg-gray-100 rounded-lg transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Content */}
        <div className="p-6 space-y-8">
          {/* Verification Status */}
          <div className="flex items-center">
            <div className={`flex items-center px-4 py-2 rounded-lg ${
              company.is_verified 
                ? 'bg-green-50 text-green-700' 
                : 'bg-yellow-50 text-yellow-700'
            }`}>
              <Shield className="w-5 h-5 mr-2" />
              <span className="font-medium">
                {company.is_verified ? 'Verifierat företag' : 'Ej verifierat'}
              </span>
            </div>
          </div>

          {/* Description */}
          {company.description && (
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-3">
                Om företaget
              </h3>
              <p className="text-gray-600 whitespace-pre-line">
                {company.description}
              </p>
            </div>
          )}

          {/* Services */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-3">
              Tjänster
            </h3>
            <div className="flex flex-wrap gap-2">
              {company.services.map((service) => (
                <span
                  key={service}
                  className="inline-flex items-center px-3 py-1 rounded-lg text-sm font-medium bg-coral-50 text-coral-700"
                >
                  <CheckCircle className="w-4 h-4 mr-1.5" />
                  {service}
                </span>
              ))}
            </div>
          </div>

          {/* Certifications */}
          {company.certifications.length > 0 && (
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-3">
                Certifieringar
              </h3>
              <div className="flex flex-wrap gap-2">
                {company.certifications.map((cert) => (
                  <span
                    key={cert}
                    className="inline-flex items-center px-3 py-1 rounded-lg text-sm font-medium bg-blue-50 text-blue-700"
                  >
                    <Shield className="w-4 h-4 mr-1.5" />
                    {cert}
                  </span>
                ))}
              </div>
            </div>
          )}

          {/* Contact Information */}
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-3">
              Kontaktuppgifter
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 bg-gray-50 rounded-xl p-6">
              {company.phone && (
                <div>
                  <div className="text-sm font-medium text-gray-500 mb-2">
                    Telefon
                  </div>
                  <a
                    href={`tel:${company.phone}`}
                    className="flex items-center text-coral-500 hover:text-coral-600"
                  >
                    <Phone className="w-5 h-5 mr-2" />
                    {company.phone}
                  </a>
                </div>
              )}
              {company.email && (
                <div>
                  <div className="text-sm font-medium text-gray-500 mb-2">
                    E-post
                  </div>
                  <a
                    href={`mailto:${company.email}`}
                    className="flex items-center text-coral-500 hover:text-coral-600"
                  >
                    <Mail className="w-5 h-5 mr-2" />
                    {company.email}
                  </a>
                </div>
              )}
              {company.website && (
                <div>
                  <div className="text-sm font-medium text-gray-500 mb-2">
                    Hemsida
                  </div>
                  <a
                    href={company.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center text-coral-500 hover:text-coral-600"
                  >
                    <Globe className="w-5 h-5 mr-2" />
                    Besök hemsida
                  </a>
                </div>
              )}
            </div>
          </div>

          {/* Internal Notes */}
          {company.notes && (
            <div>
              <h3 className="text-lg font-semibold text-gray-900 mb-3">
                Interna anteckningar
              </h3>
              <div className="bg-yellow-50 rounded-xl p-6">
                <p className="text-gray-700 whitespace-pre-line">
                  {company.notes}
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="sticky bottom-0 bg-white border-t border-gray-100 p-6">
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-coral-500 text-white rounded-lg hover:bg-coral-600 transition-colors"
          >
            Stäng
          </button>
        </div>
      </motion.div>
    </div>
  );
};