import React from 'react';
import { Helmet } from 'react-helmet-async';
import { generateSeoTags } from '../../lib/seo';

interface SEOProps {
  title: string;
  description: string;
  canonical?: string;
  type?: string;
  image?: string;
  publishedTime?: string;
  modifiedTime?: string;
  cityName?: string;
}

export const SEO = (props: SEOProps) => {
  const { title, meta, link, schema } = generateSeoTags(props);

  return (
    <Helmet>
      <title>{title}</title>
      {meta.map((tag, index) => (
        <meta key={index} {...tag} />
      ))}
      {link.map((tag, index) => (
        <link key={index} {...tag} />
      ))}
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};