import React from 'react';
import { motion } from 'framer-motion';
import { Shield, CheckCircle, AlertCircle } from 'lucide-react';
import { SEO } from '../components/shared/SEO';

export const TermsOfService = () => {
  const features = [
    {
      icon: Shield,
      title: 'Trygg användning',
      description: 'Vi värnar om din integritet och säkerhet när du använder våra tjänster.'
    },
    {
      icon: CheckCircle,
      title: 'Rättvisa villkor',
      description: 'Tydliga och transparenta villkor för alla parter.'
    },
    {
      icon: AlertCircle,
      title: 'Tydliga riktlinjer',
      description: 'Klara regler för användning av våra tjänster.'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <SEO 
        title="Användarvillkor"
        description="Läs om villkoren för användning av Flytti.se:s tjänster"
      />
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
        <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
          {/* Header */}
          <div className="bg-gradient-to-br from-coral-50 to-white p-8 md:p-12 border-b border-gray-100">
            <h1 className="text-3xl md:text-4xl font-playfair font-bold text-gray-900 mb-6">
              Användarvillkor
            </h1>
            <p className="text-gray-600">
              Senast uppdaterad: 6 december 2024
            </p>
          </div>

          {/* Feature Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-8 md:p-12 bg-gray-50">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-6 rounded-xl shadow-sm"
              >
                <div className="w-12 h-12 bg-coral-50 rounded-xl flex items-center justify-center mb-4">
                  <feature.icon className="w-6 h-6 text-coral-500" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>

          {/* Content */}
          <div className="p-8 md:p-12 space-y-8">
            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                1. Acceptans av villkor
              </h2>
              <p className="text-gray-600 mb-4">
                1.1 Genom att nyttja Flytti.se accepterar du de aktuella villkoren för användande.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                2. Så fungerar Flytti.se
              </h2>
              <div className="space-y-4 text-gray-600">
                <p>2.1 Flytti.se är en kostnadsfri tjänst som ger dess användare kontakt med utvalda flyttfirmor.</p>
                <p>2.2 Genom att fylla i formuläret på Flytti.se, ger du företaget information om dina behov. Därefter blir du kontaktad av upp till tre flyttfirmor och mottar deras erbjudanden.</p>
                <p>2.3 Strax efter att du har skickat in din förfrågan via formuläret, får du en bekräftelse via e-post från Flytti.se. Denna innehåller bland annat namn på de utvalda leverantörer som kommer att kontakta dig.</p>
                <p>2.4 Om du vill att din förfrågan ska raderas från tjänsten kontaktar du info@flytti.se.</p>
                <p>2.5 I det fall det inte är möjligt att få erbjudanden från tre leverantörer, kommer det att framgå tydligt i bekräftelsen du får.</p>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                3. Användning av Flytti.se
              </h2>
              <div className="space-y-4 text-gray-600">
                <p>3.1 Det är inte tillåtet att grundlöst utge sig för att ha någon anknytning till Flytti.se eller våra samarbetspartners.</p>
                <p>3.2 Det är inte tillåtet att tillgodogöra sig, eller försöka tillgodogöra sig, icke offentliga delar av Flytti.se:s hemsida.</p>
                <p>3.3 Det är inte tillåtet att använda Flytti.se för att sprida skadlig kod.</p>
                <p>3.4 Det är inte tillåtet att negativt påverka andra användares möjlighet att nyttja Flytti.se.</p>
                <p>3.5 Det är inte tillåtet att utge sig för att vara en annan person.</p>
                <p>3.6 Vid misstanke om användning som strider mot villkoren kan Flytti.se spärra tjänsten för aktuell användare.</p>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                4. Ansvar och förhållande till leverantörer
              </h2>
              <div className="space-y-4 text-gray-600">
                <p>4.1 Flytti.se är endast involverad i upprättandet av kontakt mellan användaren och leverantören.</p>
                <p>4.2 De anslutna flyttfirmorna verkar oberoende av Flytti.se.</p>
                <p>4.3 Användandet av Flytti.se sker på eget ansvar.</p>
                <p>4.4 Flytti.se samarbetar med utvalda, oberoende flyttfirmor.</p>
                <p>4.5 Flytti.se ansvarar inte för force majeure.</p>
                <p>4.6 Flytti.se har inte något ansvar för skatte- eller avgiftsmässiga effekter.</p>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                5. Immateriella rättigheter
              </h2>
              <div className="space-y-4 text-gray-600">
                <p>5.1 Samtliga immateriella rättigheter relaterade till Flytti.se omfattar namn, varumärken och upphovsrätt.</p>
                <p>5.2 Användandet av Flytti.se ger inte tillstånd att använda tredje parts immateriella rättigheter.</p>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                6. Kommunikation
              </h2>
              <div className="space-y-4 text-gray-600">
                <p>6.1 All kommunikation med Flytti.se sker elektroniskt.</p>
                <p>6.2 Vid nyttjande av Flytti.se blir användaren inte uppsatt på något nyhetsbrev.</p>
                <p>6.3 Vid felaktig kontaktinformation har Flytti.se rätt att korrigera denna baserat på offentlig information.</p>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                7. Integritetsskydd och personuppgifter
              </h2>
              <div className="space-y-4 text-gray-600">
                <p>7.1 Användande av Flytti.se är underställt GDPR.</p>
                <p>7.2 Information som uppges används endast för att generera relevanta erbjudanden.</p>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                8. Förändringar
              </h2>
              <div className="space-y-4 text-gray-600">
                <p>8.1 Vi förbehåller oss rätten att när som helst göra ändringar av användarvillkoren.</p>
                <p>8.2 Fortsatt användning efter ändringar utgör acceptans av de nya villkoren.</p>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                9. Lagföring och tvister
              </h2>
              <div className="space-y-4 text-gray-600">
                <p>9.1 Användandet av Flytti.se är underställt svensk lag.</p>
                <p>9.2 Tvister ska i första hand lösas genom direkt överenskommelse mellan parterna.</p>
              </div>
            </section>

            <section className="bg-gray-50 rounded-xl p-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Har du frågor om våra villkor?
              </h2>
              <p className="text-gray-600 mb-4">
                Kontakta oss på{' '}
                <a 
                  href="mailto:info@flytti.se"
                  className="text-coral-500 hover:text-coral-600 transition-colors"
                >
                  info@flytti.se
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};