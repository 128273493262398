import { useState, useEffect } from 'react';
import { Article } from '../lib/supabase';
import { fetchArticles } from '../lib/api/articles';
import toast from 'react-hot-toast';

export const useArticles = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getArticles = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const data = await fetchArticles();
        setArticles(data);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to load articles';
        setError(errorMessage);
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    getArticles();
  }, []);

  return { articles, isLoading, error };
};