import React from 'react';
import { SEO } from '../components/shared/SEO';
import { Home, Shield, Clock } from 'lucide-react';
import { PageHero } from '../components/shared/PageHero';
import { RecoWidget } from '../components/ui/RecoWidget';
import { EstateFeatures } from '../components/estate/EstateFeatures';
import { EstateProcess } from '../components/estate/EstateProcess';
import { EstateServices } from '../components/estate/EstateServices';
import { EstateWhyUs } from '../components/estate/EstateWhyUs';
import { EstateGuarantees } from '../components/estate/EstateGuarantees';
import { ServiceGrid } from '../components/shared/ServiceGrid';
import { ServiceRequestForm } from '../components/shared/ServiceRequestForm';

export const EstateHelp = () => {
  const stats = [
    { icon: Home, text: "Professionell hantering" },
    { icon: Shield, text: "Verifierade partners" },
    { icon: Clock, text: "Smidig process" }
  ];

  const handleCtaClick = () => {
    const formElement = document.getElementById('service-request-form');
    if (formElement) {
      const headerOffset = 100;
      const elementPosition = formElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Dödsbohantering - Professionell hjälp"
        description="Vi hjälper dig hitta erfarna företag för värdering, tömning och hantering av dödsbon. Jämför offerter och få skräddarsydda lösningar. Kostnadsfritt och utan förpliktelser."
      />
      <PageHero
        title="Professionell"
        titleHighlight="dödsbohantering"
        description="Vi hjälper dig hitta erfarna företag för värdering, tömning och hantering av dödsbon. Varsam och respektfull service."
        stats={stats}
        ctaText="Få offerter nu"
        ctaAction={handleCtaClick}
      />
      <RecoWidget />
      <EstateFeatures />
      <EstateProcess />
      <ServiceRequestForm />
      <EstateServices />
      <EstateWhyUs />
      <EstateGuarantees />
      <ServiceGrid />
    </div>
  );
};