import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Building2, FileText } from 'lucide-react';
import { useStats } from '../../hooks/useStats';

export const DashboardStats = () => {
  const { stats, isLoading, error } = useStats();

  if (isLoading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {[...Array(2)].map((_, index) => (
          <div
            key={index}
            className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-sm animate-pulse"
          >
            <div className="h-12 w-12 bg-gray-200 rounded-xl mb-4" />
            <div className="h-6 w-24 bg-gray-200 rounded mb-2" />
            <div className="h-4 w-48 bg-gray-200 rounded" />
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 rounded-xl p-6">
        <p className="text-red-600 mb-4">Ett fel uppstod när statistiken skulle hämtas.</p>
        <button
          onClick={() => window.location.reload()}
          className="text-coral-500 hover:text-coral-600 font-medium"
        >
          Försök igen
        </button>
      </div>
    );
  }

  const cards = [
    {
      title: 'Aktiva företag',
      value: stats.companies,
      icon: Building2,
      link: '/admin/companies',
      description: 'Lägg till och redigera företag i databasen',
      bgColor: 'bg-gradient-to-br from-coral-50 to-coral-100'
    },
    {
      title: 'Antal artiklar',
      value: stats.articles,
      icon: FileText,
      link: '/admin/articles',
      description: 'Skapa och redigera artiklar för webbplatsen',
      bgColor: 'bg-gradient-to-br from-blue-50 to-blue-100'
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {cards.map((card, index) => (
        <Link key={card.title} to={card.link}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            className={`rounded-2xl p-6 shadow-sm hover:shadow-md transition-all duration-300 ${card.bgColor}`}
          >
            <div className="flex items-start space-x-4">
              <div className="flex-shrink-0">
                <div className="w-12 h-12 bg-white rounded-xl flex items-center justify-center">
                  <card.icon className="h-6 w-6 text-coral-500" />
                </div>
              </div>
              <div className="flex-1">
                <div className="flex items-baseline">
                  <h2 className="text-2xl font-semibold text-gray-900">
                    {card.value}
                  </h2>
                  <span className="ml-2 text-sm font-medium text-gray-600">
                    totalt
                  </span>
                </div>
                <p className="mt-1 text-base font-medium text-gray-900">
                  {card.title}
                </p>
                <p className="mt-2 text-sm text-gray-600">
                  {card.description}
                </p>
              </div>
            </div>
          </motion.div>
        </Link>
      ))}
    </div>
  );
};