import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FileText, Calendar } from 'lucide-react';
import { format } from 'date-fns';
import { Article } from '../../lib/supabase';

interface ArticleListProps {
  articles: Article[];
}

export const ArticleList = ({ articles }: ArticleListProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {articles.map((article, index) => (
        <motion.article
          key={article.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
          className="group"
        >
          <Link
            to={`/artiklar/${article.slug}`}
            className="block bg-white rounded-xl p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500 h-full"
          >
            <div className="flex items-center gap-4 mb-6">
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-coral-50 text-coral-700">
                <FileText className="w-4 h-4 mr-2" />
                {article.category}
              </span>
              <time className="flex items-center text-sm text-gray-500">
                <Calendar className="w-4 h-4 mr-2" />
                {format(new Date(article.created_at), 'yyyy-MM-dd')}
              </time>
            </div>

            <h3 className="text-xl font-semibold text-gray-900 group-hover:text-coral-500 transition-colors duration-300 mb-4 line-clamp-2">
              {article.title}
            </h3>

            <p className="text-gray-600 leading-relaxed mb-6 line-clamp-3">
              {article.content.substring(0, 150)}...
            </p>

            <span className="inline-flex items-center text-coral-500 font-medium group-hover:translate-x-1 transition-transform duration-300">
              Läs mer
              <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M5 12h14M12 5l7 7-7 7"/>
              </svg>
            </span>
          </Link>
        </motion.article>
      ))}
    </div>
  );
};