import { create } from 'zustand';
import { db, Company, Article } from '../lib/supabase';
import toast from 'react-hot-toast';

interface AdminStore {
  companies: Company[];
  articles: Article[];
  isLoading: boolean;
  error: string | null;
  fetchCompanies: () => Promise<void>;
  fetchArticles: () => Promise<void>;
  deleteCompany: (id: string) => Promise<void>;
  deleteArticle: (id: string) => Promise<void>;
}

export const useAdminStore = create<AdminStore>((set) => ({
  companies: [],
  articles: [],
  isLoading: false,
  error: null,

  fetchCompanies: async () => {
    set({ isLoading: true, error: null });
    try {
      const { data, error } = await db.companies.getAll();
      if (error) throw error;
      set({ companies: data || [], isLoading: false });
    } catch (error) {
      console.error('Error fetching companies:', error);
      set({ error: 'Failed to fetch companies', isLoading: false });
      toast.error('Kunde inte hämta företag');
    }
  },

  fetchArticles: async () => {
    set({ isLoading: true, error: null });
    try {
      const { data, error } = await db.articles.getAll();
      if (error) throw error;
      set({ articles: data || [], isLoading: false });
    } catch (error) {
      console.error('Error fetching articles:', error);
      set({ error: 'Failed to fetch articles', isLoading: false });
      toast.error('Kunde inte hämta artiklar');
    }
  },

  deleteCompany: async (id: string) => {
    try {
      const { error } = await db.companies.delete(id);
      if (error) throw error;
      
      set(state => ({
        companies: state.companies.filter(company => company.id !== id)
      }));
      toast.success('Företaget har tagits bort');
    } catch (error) {
      console.error('Error deleting company:', error);
      set({ error: 'Failed to delete company' });
      toast.error('Kunde inte ta bort företaget');
    }
  },

  deleteArticle: async (id: string) => {
    try {
      const { error } = await db.articles.delete(id);
      if (error) throw error;
      
      set(state => ({
        articles: state.articles.filter(article => article.id !== id)
      }));
      toast.success('Artikeln har tagits bort');
    } catch (error) {
      console.error('Error deleting article:', error);
      set({ error: 'Failed to delete article' });
      toast.error('Kunde inte ta bort artikeln');
    }
  }
}));