import React from 'react';
import { motion } from 'framer-motion';
import { MessageSquare, FileText, CheckCircle } from 'lucide-react';

const steps = [
  {
    number: '01',
    icon: MessageSquare,
    title: 'Beskriv dina behov',
    description: 'Fyll i vårt snabba formulär - det tar mindre än en minut!'
  },
  {
    number: '02',
    icon: FileText,
    title: 'Få offerter',
    description: 'Upp till tre flyttfirmor kontaktar dig med sina bästa erbjudanden.'
  },
  {
    number: '03',
    icon: CheckCircle,
    title: 'Välj och boka',
    description: 'Jämför och välj det bästa erbjudandet för din situation.'
  }
];

export const HowItWorks = () => {
  return (
    <section className="py-32 bg-gradient-to-b from-white to-coral-50/10 overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h2 className="text-4xl md:text-5xl font-playfair font-bold mb-6">
            Så fungerar det
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            Vi gör din flytt enkel och stressfri
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: [0.21, 0.45, 0.27, 0.9]
              }}
              className="group relative"
            >
              {/* Floating Number */}
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 0.1, scale: 1 }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="absolute -top-8 -left-4 text-[120px] font-playfair font-bold text-coral-500/10 select-none pointer-events-none"
              >
                {step.number}
              </motion.div>

              <div className="relative bg-white rounded-2xl p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500">
                <div className="relative mb-6 group-hover:scale-110 transition-transform duration-500">
                  <div className="w-16 h-16 bg-coral-50 rounded-2xl flex items-center justify-center relative overflow-hidden">
                    <step.icon className="w-8 h-8 text-coral-500 relative z-10 transform group-hover:scale-110 transition-transform duration-500" />
                    <div className="absolute inset-0 bg-gradient-to-br from-coral-100/50 to-white opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
                  </div>
                </div>

                <h3 className="text-2xl font-playfair font-semibold mb-4 group-hover:text-coral-500 transition-colors duration-300">
                  {step.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {step.description}
                </p>

                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};