import React from 'react';
import { Link } from 'react-router-dom';
import { X, MessageSquare, LogIn } from 'lucide-react';
import type { NavLink } from '../../types/navigation';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  mainLinks: NavLink[];
  dropdownLinks: NavLink[];
  isAuthenticated: boolean;
}

export const MobileMenu = ({ 
  isOpen, 
  onClose, 
  mainLinks, 
  dropdownLinks,
  isAuthenticated 
}: MobileMenuProps) => {
  if (!isOpen) return null;

  return (
    <>
      {/* Overlay */}
      <div 
        className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40"
        onClick={onClose}
      />

      {/* Menu */}
      <div className="fixed inset-y-0 right-0 w-full max-w-sm bg-white shadow-xl z-50">
        <div className="flex flex-col h-full">
          {/* Close button */}
          <div className="absolute right-4 top-4">
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
              aria-label="Stäng meny"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          {/* Links */}
          <div className="flex-1 px-6 py-24 overflow-y-auto">
            <nav className="space-y-2">
              {mainLinks.map((link) => (
                <Link
                  key={link.name}
                  to={link.href}
                  className="flex items-center gap-3 px-4 py-3 rounded-xl text-gray-600 hover:bg-gray-50 hover:text-coral-500 transition-colors"
                  onClick={onClose}
                >
                  <link.icon className="w-5 h-5 flex-shrink-0" />
                  <span className="font-medium">{link.name}</span>
                </Link>
              ))}

              <div className="my-4 border-t border-gray-100" />

              {dropdownLinks.map((link) => (
                <Link
                  key={link.name}
                  to={link.href}
                  className="flex items-center gap-3 px-4 py-3 rounded-xl text-gray-600 hover:bg-gray-50 hover:text-coral-500 transition-colors"
                  onClick={onClose}
                >
                  <link.icon className="w-5 h-5 flex-shrink-0" />
                  <span className="font-medium">{link.name}</span>
                </Link>
              ))}
            </nav>
          </div>

          {/* Action buttons */}
          <div className="p-6 border-t border-gray-100 space-y-3">
            <Link
              to="/fa-offert"
              className="flex items-center justify-center gap-2 w-full px-6 py-3 bg-coral-500 text-white rounded-xl font-medium hover:bg-coral-600 transition-colors"
              onClick={onClose}
            >
              <MessageSquare className="w-5 h-5" />
              <span>Få offerter</span>
            </Link>

            <Link
              to={isAuthenticated ? '/admin' : '/admin/login'}
              className="flex items-center justify-center gap-2 w-full px-6 py-3 border border-gray-200 rounded-xl text-gray-600 hover:bg-gray-50 transition-colors"
              onClick={onClose}
            >
              <LogIn className="w-5 h-5" />
              <span>{isAuthenticated ? 'Admin' : 'Logga in'}</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};