import React from 'react';
import { useForm } from 'react-hook-form';
import { motion } from 'framer-motion';
import { Building2, Shield, CheckCircle, ArrowRight, Mail } from 'lucide-react';
import { SEO } from '../components/shared/SEO';
import toast from 'react-hot-toast';

interface RegistrationForm {
  companyName: string;
  orgNumber: string;
  contactPerson: string;
  phone: string;
  email: string;
  message: string;
}

export const RegisterCompany = () => {
  const { register, handleSubmit, formState: { errors, isSubmitting }, reset } = useForm<RegistrationForm>();

  const onSubmit = async (data: RegistrationForm) => {
    const toastId = toast.loading('Skickar förfrågan...');

    try {
      const response = await fetch('https://hooks.zapier.com/hooks/catch/20475824/2sbh426/', {
        method: 'POST',
        mode: 'no-cors', // Important for cross-origin requests
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          timestamp: new Date().toISOString(),
          source: 'website_register_company'
        }),
      });

      // Since mode is 'no-cors', we won't get response details
      // Instead, we'll assume success if no error was thrown
      toast.success('Tack för din förfrågan! Vi återkommer inom kort.', { id: toastId });
      reset();
    } catch (error) {
      console.error('Form submission error:', error);
      toast.error('Ett fel uppstod. Försök igen senare.', { id: toastId });
    }
  };

  const features = [
    {
      title: 'Flexibelt',
      description: 'Ingen bindningstid eller uppsägningstid'
    },
    {
      title: 'Anpassningsbart',
      description: 'Välj antal förfrågningar per dag, vecka eller månad'
    },
    {
      title: 'Skräddarsytt',
      description: 'Filtrera leads baserat på geografi, kundtyp och uppdragstyp'
    },
    {
      title: 'Begränsad konkurrens',
      description: 'Varje lead delas med max två andra flyttfirmor'
    },
    {
      title: 'Rättvist',
      description: 'Betala endast för mottagna leads, fakturering i efterskott'
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <SEO 
        title="Anslut ditt företag"
        description="Bli partner med Flytti.se och få fler kunder till din flyttfirma"
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-playfair font-bold mb-6">
            Vill din flyttfirma få{' '}
            <span className="text-coral-500">fler kunder?</span>
          </h1>
          <p className="text-lg text-gray-600">
            Vi kopplar dig samman med privatpersoner och företag som söker flyttjänster, 
            oavsett var i landet du befinner dig. Dessa leads är ett kostnadseffektivt 
            och enkelt sätt att öka din försäljning.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
          {/* Form Section */}
          <div className="bg-white rounded-2xl shadow-sm p-8">
            <div className="mb-8">
              <h2 className="text-2xl font-semibold mb-2">Anslut dig</h2>
              <p className="text-gray-600">
                Fyll i formuläret eller maila oss direkt på{' '}
                <a href="mailto:info@flytti.se" className="text-coral-500 hover:text-coral-600">
                  info@flytti.se
                </a>
              </p>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Företagsnamn *
                </label>
                <input
                  type="text"
                  {...register('companyName', { required: 'Detta fält är obligatoriskt' })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                />
                {errors.companyName && (
                  <p className="mt-1 text-sm text-red-600">{errors.companyName.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Organisationsnummer *
                </label>
                <input
                  type="text"
                  {...register('orgNumber', { required: 'Detta fält är obligatoriskt' })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                />
                {errors.orgNumber && (
                  <p className="mt-1 text-sm text-red-600">{errors.orgNumber.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Kontaktperson *
                </label>
                <input
                  type="text"
                  {...register('contactPerson', { required: 'Detta fält är obligatoriskt' })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                />
                {errors.contactPerson && (
                  <p className="mt-1 text-sm text-red-600">{errors.contactPerson.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Telefon *
                </label>
                <input
                  type="tel"
                  {...register('phone', { required: 'Detta fält är obligatoriskt' })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                />
                {errors.phone && (
                  <p className="mt-1 text-sm text-red-600">{errors.phone.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  E-post *
                </label>
                <input
                  type="email"
                  {...register('email', { 
                    required: 'Detta fält är obligatoriskt',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Ogiltig e-postadress'
                    }
                  })}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Meddelande
                </label>
                <textarea
                  {...register('message')}
                  rows={4}
                  placeholder="Berätta gärna mer om er verksamhet och vilka tjänster ni erbjuder..."
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-coral-500 focus:border-transparent"
                />
              </div>

              <motion.button
                type="submit"
                disabled={isSubmitting}
                whileHover={{ scale: isSubmitting ? 1 : 1.02 }}
                whileTap={{ scale: isSubmitting ? 1 : 0.98 }}
                className={`w-full px-6 py-3 bg-coral-500 text-white rounded-lg font-medium transition-all duration-300 flex items-center justify-center space-x-2
                  ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-coral-600'}`}
              >
                <span>{isSubmitting ? 'Skickar...' : 'Skicka förfrågan'}</span>
                {!isSubmitting && <ArrowRight className="w-5 h-5" />}
              </motion.button>
            </form>
          </div>

          {/* Features Section */}
          <div className="space-y-12">
            <div>
              <h2 className="text-2xl font-semibold mb-6">Fördelar med Flytti.se</h2>
              <div className="space-y-6">
                {features.map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: 20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-start gap-4"
                  >
                    <div className="w-12 h-12 bg-coral-50 rounded-xl flex items-center justify-center flex-shrink-0">
                      <CheckCircle className="w-6 h-6 text-coral-500" />
                    </div>
                    <div>
                      <h3 className="font-semibold mb-1">{feature.title}</h3>
                      <p className="text-gray-600">{feature.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>

            <div className="bg-gray-50 rounded-xl p-8">
              <h2 className="text-2xl font-semibold mb-4">Krav för partnerskap</h2>
              <div className="flex items-center gap-3 text-gray-600">
                <Shield className="w-5 h-5 text-coral-500" />
                <span>Giltigt yrkestrafiktillstånd</span>
              </div>
            </div>

            <div>
              <h2 className="text-2xl font-semibold mb-6">Kvalitet genom erfarenhet</h2>
              <p className="text-gray-600 mb-6">
                Vårt erfarna team på Flytti.se levererar högkvalitativa, "heta" leads direkt till dig. 
                Vi förstår flyttbranschens dynamik och matchar dig med kunder som aktivt söker dina tjänster.
              </p>
              <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-50 rounded-xl p-4">
                  <h3 className="font-semibold mb-2">Precision i timing</h3>
                  <p className="text-sm text-gray-600">Vi hittar kunder redo för flytthjälp</p>
                </div>
                <div className="bg-gray-50 rounded-xl p-4">
                  <h3 className="font-semibold mb-2">GDPR-kompatibel</h3>
                  <p className="text-sm text-gray-600">Säker hantering av alla förfrågningar</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};