import React from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Building2, MapPin, Phone, Mail, Globe, Shield, CheckCircle } from 'lucide-react';
import { useCompany } from '../hooks/useCompany';
import { SEO } from '../components/shared/SEO';

export const CompanyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { company, isLoading, error } = useCompany(id);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-coral-500" />
      </div>
    );
  }

  if (error || !company) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
            Företaget kunde inte hittas
          </h2>
          <Link
            to="/foretag"
            className="inline-flex items-center text-coral-500 hover:text-coral-600 font-medium"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Tillbaka till företag
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <SEO 
        title={`${company.name} - Flyttfirma i ${company.city}`}
        description={company.description || `${company.name} erbjuder flyttjänster i ${company.city}. Kontakta oss för en professionell och trygg flytt.`}
      />
      
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
        <Link
          to="/foretag"
          className="inline-flex items-center text-coral-500 hover:text-coral-600 mb-8 group"
        >
          <ArrowLeft className="w-5 h-5 mr-2 transform group-hover:-translate-x-1 transition-transform" />
          Tillbaka till företag
        </Link>

        <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
          {/* Header Section */}
          <div className="p-6 md:p-8 border-b border-gray-100">
            <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-6">
              <div className="flex items-start gap-4">
                <div className="w-16 h-16 bg-coral-50 rounded-xl flex items-center justify-center flex-shrink-0">
                  <Building2 className="w-8 h-8 text-coral-500" />
                </div>
                <div>
                  <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-2">
                    {company.name}
                  </h1>
                  <div className="flex items-center text-gray-600">
                    <MapPin className="w-5 h-5 mr-2 text-coral-500" />
                    {company.city}
                  </div>
                </div>
              </div>

              {company.is_verified && (
                <div className="bg-green-50 rounded-xl p-4 lg:self-center">
                  <div className="flex items-center gap-3">
                    <Shield className="w-5 h-5 text-green-600" />
                    <div>
                      <span className="font-medium text-green-700 block">
                        Verifierat företag
                      </span>
                      <span className="text-sm text-green-600">
                        Kvalitetssäkrad av Flytti.se
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Description */}
          {company.description && (
            <div className="p-6 md:p-8 bg-gray-50">
              <p className="text-gray-700 text-lg leading-relaxed">
                {company.description}
              </p>
            </div>
          )}

          {/* Services */}
          {company.services.length > 0 && (
            <div className="p-6 md:p-8 border-t border-gray-100">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">
                Tjänster
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {company.services.map((service) => (
                  <div
                    key={service}
                    className="flex items-center gap-3 p-4 bg-coral-50/50 rounded-xl"
                  >
                    <div className="w-10 h-10 bg-white rounded-lg flex items-center justify-center">
                      <CheckCircle className="w-5 h-5 text-coral-500" />
                    </div>
                    <span className="text-gray-700 font-medium">{service}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Certifications */}
          {company.certifications.length > 0 && (
            <div className="p-6 md:p-8 border-t border-gray-100 bg-gray-50">
              <h2 className="text-xl font-semibold text-gray-900 mb-6">
                Certifieringar
              </h2>
              <div className="flex flex-wrap gap-4">
                {company.certifications.map((cert) => (
                  <div
                    key={cert}
                    className="flex items-center gap-3 px-4 py-3 bg-white rounded-xl shadow-sm"
                  >
                    <Shield className="w-5 h-5 text-coral-500" />
                    <span className="font-medium text-gray-700">{cert}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Contact Information */}
          <div className="p-6 md:p-8 border-t border-gray-100">
            <h2 className="text-xl font-semibold text-gray-900 mb-6">
              Kontaktuppgifter
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {company.phone && (
                <a
                  href={`tel:${company.phone}`}
                  className="flex items-center gap-3 p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
                >
                  <div className="w-10 h-10 bg-white rounded-lg flex items-center justify-center">
                    <Phone className="w-5 h-5 text-coral-500" />
                  </div>
                  <div>
                    <span className="text-sm text-gray-500 block">Ring oss</span>
                    <span className="text-coral-500 font-medium">{company.phone}</span>
                  </div>
                </a>
              )}

              {company.email && (
                <a
                  href={`mailto:${company.email}`}
                  className="flex items-center gap-3 p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
                >
                  <div className="w-10 h-10 bg-white rounded-lg flex items-center justify-center">
                    <Mail className="w-5 h-5 text-coral-500" />
                  </div>
                  <div>
                    <span className="text-sm text-gray-500 block">Maila oss</span>
                    <span className="text-coral-500 font-medium break-all">{company.email}</span>
                  </div>
                </a>
              )}

              {company.website && (
                <a
                  href={company.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-3 p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
                >
                  <div className="w-10 h-10 bg-white rounded-lg flex items-center justify-center">
                    <Globe className="w-5 h-5 text-coral-500" />
                  </div>
                  <div>
                    <span className="text-sm text-gray-500 block">Besök hemsida</span>
                    <span className="text-coral-500 font-medium">Klicka här</span>
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};