import React from 'react';
import { motion } from 'framer-motion';
import { Home, Clock, TrendingDown, CheckCircle } from 'lucide-react';

const features = [
  {
    icon: Home,
    title: 'Professionell hantering',
    description: 'Erfarna företag som hanterar dödsbon med respekt'
  },
  {
    icon: Clock,
    title: 'Smidig process',
    description: 'Vi tar hand om hela processen från start till mål'
  },
  {
    icon: TrendingDown,
    title: 'Konkurrenskraftigt',
    description: 'Jämför priser från kvalitetssäkrade företag'
  },
  {
    icon: CheckCircle,
    title: 'Komplett service',
    description: 'Från värdering till bortforsling och städning'
  }
];

export const EstateFeatures = () => {
  return (
    <section className="relative py-32 bg-section-light overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: [0.21, 0.45, 0.27, 0.9]
              }}
              className="group relative"
            >
              <div className="relative bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500">
                <div className="relative mb-6">
                  <div className="w-16 h-16 bg-coral-50 rounded-xl flex items-center justify-center group-hover:bg-coral-100 transition-transform duration-500 group-hover:scale-110">
                    <feature.icon className="w-8 h-8 text-coral-500 transform group-hover:scale-110 transition-transform duration-500" />
                    <div className="absolute inset-0 bg-coral-200/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                  </div>
                </div>

                <h3 className="text-xl font-semibold mb-3 group-hover:text-coral-500 transition-colors duration-300">
                  {feature.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {feature.description}
                </p>

                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};