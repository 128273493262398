import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { LazyImage } from '../shared/LazyImage';

export const InternationalMoveWhyUs = () => {
  return (
    <section className="py-32 bg-section-light overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="relative"
          >
            <div className="aspect-square rounded-2xl overflow-hidden">
              <LazyImage
                src="https://res.cloudinary.com/acasting-prod/image/upload/v1733523955/blog-articles/cqsg8udicybnvgvps3i4.jpg"
                alt="Flytti.se - Utlandsflytt"
                className="w-full h-full object-cover"
                priority={true}
              />
              <div className="absolute inset-0 bg-gradient-to-br from-coral-500/20 to-transparent" />
            </div>
            
            {/* Premium Image Effects */}
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1 }}
              className="absolute -inset-4 bg-gradient-to-br from-coral-100/30 to-transparent rounded-2xl blur-2xl -z-10"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="space-y-8"
          >
            <h2 className="text-4xl md:text-5xl font-playfair font-bold">
              Varför anlita professionell{' '}
              <span className="text-coral-500">utlandsflytt?</span>
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed">
              En internationell flytt innebär många utmaningar som skiljer sig från en vanlig flytt inom landet. 
              Det handlar inte bara om att transportera dina ägodelar – det finns också tull, olika länders regelverk 
              och internationell logistik att ta hänsyn till.
            </p>
            <p className="text-gray-600 text-lg leading-relaxed">
              Professionella flyttfirmor med erfarenhet av internationella flyttar kan hjälpa dig navigera genom 
              denna komplexa process. De har kunskap om dokumentation, tullregler och internationella transportlösningar 
              som gör din flytt smidig och trygg.
            </p>
            <Link
              to="/fa-offert"
              className="inline-flex items-center px-8 py-4 bg-coral-500 text-white rounded-xl font-medium shadow-lg hover:bg-coral-600 transition-all duration-300 group"
            >
              <span>Få offerter nu</span>
              <ArrowRight className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};