import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Eye, Mail } from 'lucide-react';
import { SEO } from '../components/shared/SEO';

export const PrivacyPolicy = () => {
  const sections = [
    {
      icon: Shield,
      title: 'GDPR-kompatibel',
      description: 'Vi följer EU:s dataskyddsförordning för att skydda din integritet.'
    },
    {
      icon: Lock,
      title: 'Säker hantering',
      description: 'Din information skyddas med moderna säkerhetslösningar.'
    },
    {
      icon: Eye,
      title: 'Transparens',
      description: 'Full insyn i hur vi hanterar dina personuppgifter.'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <SEO 
        title="Dataskyddspolicy"
        description="Läs om hur vi hanterar och skyddar dina personuppgifter på Flytti.se"
      />
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
        <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
          {/* Header */}
          <div className="bg-gradient-to-br from-coral-50 to-white p-8 md:p-12 border-b border-gray-100">
            <h1 className="text-3xl md:text-4xl font-playfair font-bold text-gray-900 mb-6">
              Dataskyddspolicy
            </h1>
            <p className="text-gray-600">
              Senast uppdaterad: 6 december 2024
            </p>
          </div>

          {/* Feature Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-8 md:p-12 bg-gray-50">
            {sections.map((section, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-6 rounded-xl shadow-sm"
              >
                <div className="w-12 h-12 bg-coral-50 rounded-xl flex items-center justify-center mb-4">
                  <section.icon className="w-6 h-6 text-coral-500" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {section.title}
                </h3>
                <p className="text-gray-600">
                  {section.description}
                </p>
              </motion.div>
            ))}
          </div>

          {/* Content Sections */}
          <div className="p-8 md:p-12 space-y-8">
            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Personuppgiftshantering
              </h2>
              <p className="text-gray-600 mb-4">
                Här kan du läsa mer om hur företaget Redokollen AB, som äger och driver Flytti.se, behandlar dina personuppgifter.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Vilka personuppgifter behandlas?
              </h2>
              <p className="text-gray-600 mb-4">
                Personuppgifterna vi efterfrågar är endast det minimum som flyttfirmorna behöver för att kunna ge dig ett relevant och konkret erbjudande. Uppgifterna används inte till något annat än att ge dig erbjudanden, och till det administrativa arbete som är knutet till detta.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Cookies och spårning
              </h2>
              <p className="text-gray-600 mb-4">
                Vi använder cookies för att förbättra din upplevelse på vår webbplats. Du kan när som helst ändra dina cookie-inställningar eller återkalla ditt samtycke.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                E-postkommunikation
              </h2>
              <p className="text-gray-600 mb-4">
                Du kommer endast att få ett bekräftelsemail från oss efter att du skickat in din förfrågan. Detta mail innehåller information om vilka flyttfirmor som kommer att kontakta dig.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Dina rättigheter
              </h2>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Rätt till insyn i vilka uppgifter vi har om dig</li>
                <li>Rätt att få felaktiga uppgifter rättade</li>
                <li>Rätt att bli raderad ("rätten att bli bortglömd")</li>
                <li>Rätt att begränsa behandlingen av dina uppgifter</li>
                <li>Rätt att få ut dina uppgifter i ett maskinläsbart format</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Säkerhet
              </h2>
              <p className="text-gray-600 mb-4">
                Vi använder moderna säkerhetslösningar för att skydda dina personuppgifter mot obehörig åtkomst, förlust eller manipulation.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Lagringstid
              </h2>
              <p className="text-gray-600 mb-4">
                Vi sparar dina uppgifter så länge det är relevant för att kunna leverera tjänsten, men maximalt i fem år. Normalt raderas alla uppgifter inom sex månader.
              </p>
            </section>

            <section className="bg-gray-50 rounded-xl p-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Kontakta oss
              </h2>
              <p className="text-gray-600 mb-6">
                Om du har frågor om vår dataskyddspolicy eller vill utöva dina rättigheter, kontakta oss på:
              </p>
              <div className="space-y-4">
                <div className="flex items-start gap-3">
                  <Mail className="w-5 h-5 text-coral-500 mt-1" />
                  <div>
                    <p className="font-medium text-gray-900">Redokollen AB</p>
                    <p className="text-gray-600">Stjärntorget 2</p>
                    <p className="text-gray-600">169 79 Solna</p>
                  </div>
                </div>
                <div>
                  <p className="text-gray-600">Org.nr.: 559336-7526</p>
                  <a 
                    href="mailto:info@flytti.se"
                    className="text-coral-500 hover:text-coral-600 transition-colors"
                  >
                    info@flytti.se
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};