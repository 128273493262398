// Enhanced analytics tracking
export const analytics = {
  pageView: (path: string) => {
    if (typeof window !== 'undefined') {
      // Track page views
      window.gtag?.('config', 'G-XXXXXXXX', { page_path: path });
      
      // Track performance metrics
      const metrics = window.performance?.getEntriesByType('navigation')[0];
      if (metrics) {
        window.gtag?.('event', 'page_performance', {
          loadTime: metrics.loadEventEnd - metrics.loadEventStart,
          domReady: metrics.domContentLoadedEventEnd - metrics.domContentLoadedEventStart,
        });
      }
    }
  },

  trackEvent: (category: string, action: string, label?: string, value?: number) => {
    window.gtag?.('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  },

  trackError: (error: Error, componentStack?: string) => {
    window.gtag?.('event', 'error', {
      error_name: error.name,
      error_message: error.message,
      error_stack: error.stack,
      component_stack: componentStack,
    });
  }
};