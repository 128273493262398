import React from 'react';
import { SEO } from '../components/shared/SEO';
import { Sparkles, Shield, Star } from 'lucide-react';
import { PageHero } from '../components/shared/PageHero';
import { RecoWidget } from '../components/ui/RecoWidget';
import { CleaningFeatures } from '../components/cleaning/CleaningFeatures';
import { CleaningProcess } from '../components/cleaning/CleaningProcess';
import { CleaningServices } from '../components/cleaning/CleaningServices';
import { CleaningWhyUs } from '../components/cleaning/CleaningWhyUs';
import { CleaningGuarantees } from '../components/cleaning/CleaningGuarantees';
import { ServiceGrid } from '../components/shared/ServiceGrid';
import { ServiceRequestForm } from '../components/shared/ServiceRequestForm';

export const CleaningHelp = () => {
  const stats = [
    { icon: Sparkles, text: "Professionell städning" },
    { icon: Shield, text: "Verifierade partners" },
    { icon: Star, text: "Nöjd-kund-garanti" }
  ];

  const handleCtaClick = () => {
    const formElement = document.getElementById('service-request-form');
    if (formElement) {
      const headerOffset = 100;
      const elementPosition = formElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <SEO 
        title="Flyttstädning - Hitta rätt städfirma"
        description="Jämför priser och få offerter från kvalitetssäkrade städfirmor. Professionell flyttstädning med nöjd-kund-garanti. Kostnadsfritt och utan förpliktelser."
      />
      <PageHero
        title="Professionell"
        titleHighlight="flyttstädning"
        description="Vi hjälper dig hitta rätt städfirma för dina behov. Jämför offerter och få skräddarsydda lösningar för just din situation."
        stats={stats}
        ctaText="Få offerter nu"
        ctaAction={handleCtaClick}
      />
      <RecoWidget />
      <CleaningFeatures />
      <CleaningProcess />
      <ServiceRequestForm />
      <CleaningServices />
      <CleaningWhyUs />
      <CleaningGuarantees />
      <ServiceGrid />
    </div>
  );
};