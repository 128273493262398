import React from 'react';
import { motion } from 'framer-motion';
import { Cookie, Lock, Eye } from 'lucide-react';
import { SEO } from '../components/shared/SEO';

export const CookiePolicy = () => {
  const features = [
    {
      icon: Cookie,
      title: 'Transparent användning',
      description: 'Full insyn i hur vi använder cookies på vår webbplats.'
    },
    {
      icon: Lock,
      title: 'Din integritet',
      description: 'Du har full kontroll över vilka cookies du accepterar.'
    },
    {
      icon: Eye,
      title: 'Tydlig information',
      description: 'Enkel och klar beskrivning av våra cookies.'
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <SEO 
        title="Cookie Policy"
        description="Läs om hur vi använder cookies på Flytti.se för att förbättra din upplevelse"
      />
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
        <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
          {/* Header */}
          <div className="bg-gradient-to-br from-coral-50 to-white p-8 md:p-12 border-b border-gray-100">
            <h1 className="text-3xl md:text-4xl font-playfair font-bold text-gray-900 mb-6">
              Cookie Policy
            </h1>
            <p className="text-gray-600">
              Senast uppdaterad: 6 december 2024
            </p>
          </div>

          {/* Feature Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-8 md:p-12 bg-gray-50">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-6 rounded-xl shadow-sm"
              >
                <div className="w-12 h-12 bg-coral-50 rounded-xl flex items-center justify-center mb-4">
                  <feature.icon className="w-6 h-6 text-coral-500" />
                </div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>

          {/* Content */}
          <div className="p-8 md:p-12 space-y-8">
            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Vad är cookies?
              </h2>
              <p className="text-gray-600">
                Cookies är små textfiler som sparas på din enhet när du besöker vår webbplats. 
                De är utformade för att förbättra din upplevelse genom att komma ihåg dina 
                preferenser och anpassa innehållet efter dina behov.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Våra cookies och hur vi använder dem
              </h2>
              <div className="space-y-6">
                <div className="bg-gray-50 rounded-xl p-6">
                  <h3 className="text-xl font-semibold mb-3">1. Nödvändiga cookies</h3>
                  <p className="text-gray-600">
                    Dessa cookies är essentiella för webbplatsens grundläggande funktioner. 
                    De möjliggör säker inloggning, smidig navigering och tillgång till viktiga tjänster. 
                    Utan dessa cookies kan vi inte garantera en fullgod användarupplevelse.
                  </p>
                </div>

                <div className="bg-gray-50 rounded-xl p-6">
                  <h3 className="text-xl font-semibold mb-3">2. Analys-cookies</h3>
                  <p className="text-gray-600">
                    För att kontinuerligt förbättra vår tjänst använder vi analys-cookies. 
                    Dessa hjälper oss förstå hur besökare använder webbplatsen genom att samla 
                    in anonym statistik. Din integritet är viktig för oss – all data behandlas konfidentiellt.
                  </p>
                </div>

                <div className="bg-gray-50 rounded-xl p-6">
                  <h3 className="text-xl font-semibold mb-3">3. Funktionella cookies</h3>
                  <p className="text-gray-600">
                    För en mer personlig upplevelse använder vi funktionella cookies. 
                    De kommer ihåg dina preferenser och anpassar innehållet efter dina behov. 
                    Dessa kan komma från oss eller våra noggrant utvalda samarbetspartners.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Lagringstid för cookies
              </h2>
              <div className="space-y-4">
                <div>
                  <h3 className="text-xl font-semibold mb-2">Sessionscookies</h3>
                  <p className="text-gray-600">
                    Temporära cookies som raderas automatiskt när du stänger webbläsaren. 
                    De hjälper oss att ge dig en smidig upplevelse under ditt besök.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">Permanenta cookies</h3>
                  <p className="text-gray-600">
                    Cookies som sparas på din enhet under en bestämd tidsperiod eller tills 
                    du aktivt tar bort dem. De hjälper oss komma ihåg dina preferenser mellan besök.
                  </p>
                </div>
              </div>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Hantera dina cookie-inställningar
              </h2>
              <p className="text-gray-600">
                Du har full kontroll över vilka cookies du vill acceptera. Du kan när som helst 
                ändra dina cookie-inställningar genom att klicka på "Hantera cookies" i sidfoten. 
                Du kan också hantera cookies direkt i din webbläsares inställningar.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Tredjepartscookies
              </h2>
              <p className="text-gray-600 mb-4">
                Vi samarbetar med utvalda partners för att ge dig bästa möjliga service. 
                Dessa tredjepartstjänster kan använda cookies:
              </p>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>Google Analytics: För anonym användarstatistik och webbplatsoptimering</li>
                <li>Reco.se: För att visa verifierade omdömen och recensioner</li>
              </ul>
            </section>

            <section className="bg-gray-50 rounded-xl p-8">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Frågor om vår cookie-policy?
              </h2>
              <p className="text-gray-600 mb-4">
                Vi finns här för att hjälpa! Kontakta oss på{' '}
                <a 
                  href="mailto:info@flytti.se"
                  className="text-coral-500 hover:text-coral-600 transition-colors"
                >
                  info@flytti.se
                </a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};