import { City } from '../types';

export const cities = {
  blekinge: {
    name: 'Blekinge',
    cities: ['Karlshamn', 'Karlskrona', 'Olofström', 'Ronneby', 'Sölvesborg']
  },
  dalarna: {
    name: 'Dalarna',
    cities: ['Avesta', 'Borlänge', 'Falun', 'Gagnef', 'Hedemora', 'Leksand', 'Ludvika', 'Malung-Sälen', 'Mora', 'Orsa', 'Rättvik', 'Smedjebacken', 'Säter', 'Vansbro', 'Älvdalen']
  },
  gavleborg: {
    name: 'Gävleborg',
    cities: ['Bollnäs', 'Gävle', 'Hofors', 'Hudiksvall', 'Ljusdal', 'Nordanstig', 'Ockelbo', 'Ovanåker', 'Sandviken', 'Söderhamn']
  },
  gotland: {
    name: 'Gotland',
    cities: ['Visby']
  },
  halland: {
    name: 'Halland',
    cities: ['Falkenberg', 'Halmstad', 'Hylte', 'Kungsbacka', 'Varberg', 'Laholm']
  },
  jamtland: {
    name: 'Jämtland',
    cities: ['Berg', 'Bräcke', 'Härjedalen', 'Krokom', 'Ragunda', 'Strömsund', 'Åre', 'Östersund']
  },
  jonkoping: {
    name: 'Jönköping',
    cities: ['Aneby', 'Eksjö', 'Gislaved', 'Gnosjö', 'Habo', 'Jönköping', 'Mullsjö', 'Nässjö', 'Sävsjö', 'Tranås', 'Vaggeryd', 'Vetlanda', 'Värnamo']
  },
  kalmar: {
    name: 'Kalmar',
    cities: ['Borgholm', 'Emmaboda', 'Hultsfred', 'Högsby', 'Kalmar', 'Mönsterås', 'Mörbylånga', 'Nybro', 'Oskarshamn', 'Torsås', 'Vimmerby', 'Västervik']
  },
  kronoberg: {
    name: 'Kronoberg',
    cities: ['Alvesta', 'Lessebo', 'Ljungby', 'Markaryd', 'Tingsryd', 'Uppvidinge', 'Växjö', 'Älmhult']
  },
  norrbotten: {
    name: 'Norrbotten',
    cities: ['Arjeplog', 'Arvidsjaur', 'Boden', 'Gällivare', 'Haparanda', 'Jokkmokk', 'Kalix', 'Kiruna', 'Luleå', 'Pajala', 'Piteå', 'Älvsbyn', 'Överkalix', 'Övertorneå']
  },
  orebro: {
    name: 'Örebro',
    cities: ['Askersund', 'Degerfors', 'Hallsberg', 'Hällefors', 'Karlskoga', 'Kumla', 'Laxå', 'Lekeberg', 'Lindesberg', 'Ljusnarsberg', 'Nora', 'Örebro']
  },
  ostergotland: {
    name: 'Östergötland',
    cities: ['Boxholm', 'Finspång', 'Kinda', 'Linköping', 'Mjölby', 'Motala', 'Norrköping', 'Söderköping', 'Vadstena', 'Valdemarsvik', 'Ydre', 'Åtvidaberg', 'Ödeshög']
  },
  skane: {
    name: 'Skåne',
    cities: ['Bjuv', 'Bromölla', 'Burlöv', 'Båstad', 'Eslöv', 'Helsingborg', 'Hässleholm', 'Höganäs', 'Hörby', 'Höör', 'Klippan', 'Kristianstad', 'Landskrona', 'Lomma', 'Lund', 'Malmö', 'Osby', 'Perstorp', 'Simrishamn', 'Sjöbo', 'Skurup', 'Staffanstorp', 'Svalöv', 'Svedala', 'Tomelilla', 'Trelleborg', 'Vellinge', 'Ystad', 'Åstorp', 'Ängelholm', 'Örkelljunga', 'Östra Göinge']
  },
  sodermanland: {
    name: 'Södermanland',
    cities: ['Eskilstuna', 'Flen', 'Gnesta', 'Katrineholm', 'Nyköping', 'Oxelösund', 'Strängnäs', 'Trosa', 'Vingåker']
  },
  stockholm: {
    name: 'Stockholm',
    cities: ['Botkyrka', 'Danderyd', 'Ekerö', 'Haninge', 'Huddinge', 'Järfälla', 'Lidingö', 'Nacka', 'Norrtälje', 'Nykvarn', 'Nynäshamn', 'Salem', 'Sigtuna', 'Sollentuna', 'Solna', 'Stockholm', 'Sundbyberg', 'Södertälje', 'Tyresö', 'Täby', 'Upplands Väsby', 'Upplands-Bro', 'Vallentuna', 'Vaxholm', 'Värmdö', 'Österåker']
  },
  uppsala: {
    name: 'Uppsala',
    cities: ['Enköping', 'Heby', 'Håbo', 'Knivsta', 'Tierp', 'Uppsala', 'Älvkarleby', 'Östhammar']
  },
  varmland: {
    name: 'Värmland',
    cities: ['Arvika', 'Eda', 'Filipstad', 'Forshaga', 'Grums', 'Hagfors', 'Hammarö', 'Karlstad', 'Kil', 'Kristinehamn', 'Munkfors', 'Storfors', 'Sunne', 'Säffle', 'Torsby', 'Årjäng']
  },
  vasterbotten: {
    name: 'Västerbotten',
    cities: ['Bjurholm', 'Dorotea', 'Lycksele', 'Malå', 'Nordmaling', 'Norsjö', 'Robertsfors', 'Skellefteå', 'Sorsele', 'Storuman', 'Umeå', 'Vilhelmina', 'Vindeln', 'Vännäs', 'Åsele']
  },
  vasternorrland: {
    name: 'Västernorrland',
    cities: ['Härnösand', 'Kramfors', 'Sollefteå', 'Sundsvall', 'Timrå', 'Ånge', 'Örnsköldsvik']
  },
  vastmanland: {
    name: 'Västmanland',
    cities: ['Arboga', 'Fagersta', 'Hallstahammar', 'Kungsör', 'Köping', 'Norberg', 'Sala', 'Skinnskatteberg', 'Surahammar', 'Västerås']
  },
  vastra_gotaland: {
    name: 'Västra Götaland',
    cities: ['Ale', 'Alingsås', 'Bengtsfors', 'Bollebygd', 'Borås', 'Dals-Ed', 'Essunga', 'Falköping', 'Färgelanda', 'Grästorp', 'Gullspång', 'Göteborg', 'Götene', 'Herrljunga', 'Hjo', 'Härryda', 'Karlsborg', 'Kungälv', 'Lerum', 'Lidköping', 'Lilla Edet', 'Lysekil', 'Mariestad', 'Mark', 'Mellerud', 'Munkedal', 'Mölndal', 'Orust', 'Partille', 'Skara', 'Skövde', 'Sotenäs', 'Stenungsund', 'Strömstad', 'Svenljunga', 'Tanum', 'Tibro', 'Tidaholm', 'Tjörn', 'Tranemo', 'Trollhättan', 'Töreboda', 'Uddevalla', 'Ulricehamn', 'Vara', 'Vårgårda', 'Vänersborg', 'Åmål', 'Öckerö']
  }
} as const;

export interface CityData {
  title: string;
  description: string;
  metaDescription: string;
  services: {
    moving: string;
    cleaning: string;
    storage: string;
    estate: string;
  };
}

export const getCityData = (cityName: string): CityData => {
  return {
    title: `Flyttjänster i ${cityName}`,
    description: `Vi hjälper dig hitta kvalitetssäkrade flyttfirmor och relaterade tjänster i ${cityName}`,
    metaDescription: `Jämför priser och få offerter från kvalitetssäkrade flyttfirmor i ${cityName}. Vi hjälper dig hitta rätt flyttfirma för dina behov. Kostnadsfritt och utan förpliktelser.`,
    services: {
      moving: `Professionell flytthjälp i ${cityName} för en smidig och trygg flytt. Våra noggrant utvalda flyttfirmor har stor erfarenhet av lokala flyttar i ${cityName}-området.`,
      cleaning: `Grundlig flyttstädning i ${cityName} enligt branschstandard. Vi samarbetar endast med erfarna städfirmor som garanterar ett perfekt resultat.`,
      storage: `Säker magasinering i ${cityName} för både korttids- och långtidsförvaring. Moderna och övervakade lagerlokaler till konkurrenskraftiga priser.`,
      estate: `Professionell och respektfull hantering av dödsbon i ${cityName}. Vi hjälper er med värdering, tömning och städning av dödsbon.`
    }
  };
};