import { supabase } from '../supabase';
import type { Article } from '../supabase';

export const fetchArticleById = async (id: string): Promise<Article> => {
  try {
    const { data, error } = await supabase
      .from('articles')
      .select('*')
      .eq('id', id)
      .single();

    if (error) throw error;
    if (!data) throw new Error('Artikel hittades inte');
    
    return data;
  } catch (error) {
    console.error('Error fetching article:', error);
    throw new Error('Kunde inte hämta artikel');
  }
};

export const fetchArticleBySlug = async (slug: string): Promise<Article> => {
  try {
    const { data, error } = await supabase
      .from('articles')
      .select('*')
      .eq('slug', slug)
      .single();

    if (error) throw error;
    if (!data) throw new Error('Artikel hittades inte');
    
    return data;
  } catch (error) {
    console.error('Error fetching article by slug:', error);
    throw new Error('Kunde inte hämta artikel');
  }
};

export const fetchArticles = async (): Promise<Article[]> => {
  try {
    const { data, error } = await supabase
      .from('articles')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw new Error('Kunde inte hämta artiklar');
  }
};

export const createArticle = async (article: Partial<Article>): Promise<Article> => {
  try {
    const { data, error } = await supabase
      .from('articles')
      .insert([article])
      .select()
      .single();

    if (error) throw error;
    if (!data) throw new Error('Kunde inte skapa artikel');
    
    return data;
  } catch (error) {
    console.error('Error creating article:', error);
    throw new Error('Kunde inte skapa artikel');
  }
};

export const updateArticle = async (id: string, article: Partial<Article>): Promise<Article> => {
  try {
    const { data, error } = await supabase
      .from('articles')
      .update(article)
      .eq('id', id)
      .select()
      .single();

    if (error) throw error;
    if (!data) throw new Error('Kunde inte uppdatera artikel');
    
    return data;
  } catch (error) {
    console.error('Error updating article:', error);
    throw new Error('Kunde inte uppdatera artikel');
  }
};

export const deleteArticle = async (id: string): Promise<void> => {
  try {
    const { error } = await supabase
      .from('articles')
      .delete()
      .eq('id', id);

    if (error) throw error;
  } catch (error) {
    console.error('Error deleting article:', error);
    throw new Error('Kunde inte ta bort artikel');
  }
};