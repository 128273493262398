import React from 'react';
import { motion } from 'framer-motion';
import { Package, Shield, Clock, CheckCircle } from 'lucide-react';

const packingOptions = [
  {
    title: 'Packa själv',
    icon: Package,
    advantages: [
      'Kostnadseffektivt alternativ',
      'Flexibel packningstid',
      'Full kontroll över processen'
    ],
    disadvantages: [
      'Tidskrävande',
      'Risk för felaktig packning',
      'Begränsat försäkringsskydd',
      'Kräver egen packningsmaterial'
    ]
  },
  {
    title: 'Professionell packhjälp',
    icon: Shield,
    advantages: [
      'Expertis i korrekt packning',
      'Sparar tid och minskar stress',
      'Omfattande försäkringsskydd',
      'Professionellt packmaterial',
      'Särskild hantering av ömtåliga föremål'
    ],
    disadvantages: [
      'Högre kostnad',
      'Kräver planering av packningstid'
    ]
  }
];

export const InternationalMovePackingGuide = () => {
  return (
    <section className="relative py-32 bg-section-dark overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-br from-coral-100/30 to-transparent rounded-full blur-3xl" />
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-gradient-to-tr from-coral-50/50 to-transparent rounded-full blur-3xl" />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h2 className="text-4xl md:text-5xl font-playfair font-bold mb-6">
            Packa själv eller anlita{' '}
            <span className="relative inline-block">
              <span className="relative z-10 text-coral-500">hjälp?</span>
              <span className="absolute bottom-2 left-0 h-3 w-full bg-gradient-to-r from-coral-100 to-coral-200 -z-10" />
            </span>
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            Jämför alternativen och välj det som passar dig bäst
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {packingOptions.map((option, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.2,
                ease: [0.21, 0.45, 0.27, 0.9]
              }}
              className="group relative"
            >
              <div className="relative bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500">
                <div className="relative mb-6">
                  <div className="w-16 h-16 bg-coral-50 rounded-xl flex items-center justify-center group-hover:bg-coral-100 transition-transform duration-500 group-hover:scale-110">
                    <option.icon className="w-8 h-8 text-coral-500 transform group-hover:scale-110 transition-transform duration-500" />
                    <div className="absolute inset-0 bg-coral-200/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                  </div>
                </div>

                <h3 className="text-2xl font-playfair font-semibold mb-6 group-hover:text-coral-500 transition-colors duration-300">
                  {option.title}
                </h3>

                <div className="space-y-6">
                  <div>
                    <h4 className="font-semibold text-gray-900 mb-3 flex items-center">
                      <CheckCircle className="w-5 h-5 text-green-500 mr-2" />
                      Fördelar
                    </h4>
                    <ul className="space-y-2">
                      {option.advantages.map((advantage, i) => (
                        <li key={i} className="flex items-center text-gray-600">
                          <div className="w-1.5 h-1.5 bg-green-500 rounded-full mr-3" />
                          {advantage}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <h4 className="font-semibold text-gray-900 mb-3 flex items-center">
                      <Clock className="w-5 h-5 text-coral-500 mr-2" />
                      Nackdelar
                    </h4>
                    <ul className="space-y-2">
                      {option.disadvantages.map((disadvantage, i) => (
                        <li key={i} className="flex items-center text-gray-600">
                          <div className="w-1.5 h-1.5 bg-coral-500 rounded-full mr-3" />
                          {disadvantage}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </div>
            </motion.div>
          ))}
        </div>

        <div className="mt-12 p-6 bg-coral-50/50 rounded-xl backdrop-blur-sm">
          <p className="text-gray-600 text-center">
            <strong className="text-coral-500">Observera:</strong> Flyttfirmans försäkring täcker ofta inte egenpackat bohag. 
            För värdefulla eller ömtåliga föremål rekommenderas professionell packhjälp för maximal trygghet under transporten.
          </p>
        </div>
      </div>
    </section>
  );
};