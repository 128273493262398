import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Truck, Building2, Globe, Sparkles, Box, Home } from 'lucide-react';

const services = [
  {
    icon: Truck,
    title: 'Flytthjälp',
    description: 'Professionell hjälp med flytt och transport',
    href: '/flytthjalp'
  },
  {
    icon: Building2,
    title: 'Kontorsflytt',
    description: 'Specialanpassade lösningar för företag',
    href: '/kontorsflytt'
  },
  {
    icon: Globe,
    title: 'Utlandsflytt',
    description: 'Säker och effektiv internationell flyttservice',
    href: '/utlandsflytt'
  },
  {
    icon: Sparkles,
    title: 'Flyttstädning',
    description: 'Grundlig städning enligt branschstandard',
    href: '/flyttstadning'
  },
  {
    icon: Box,
    title: 'Magasinering',
    description: 'Säker förvaring av dina tillhörigheter',
    href: '/magasinering'
  },
  {
    icon: Home,
    title: 'Dödsbo',
    description: 'Varsam och respektfull hantering av dödsbon',
    href: '/dodsbo'
  }
];

export const ServiceGrid = () => {
  return (
    <section className="py-32 bg-gradient-to-b from-white to-coral-50/10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h2 className="text-4xl md:text-5xl font-playfair font-bold mb-6">
            Våra tjänster
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto text-lg">
            Vi erbjuder ett komplett utbud av flyttjänster för att göra din flytt så smidig som möjligt
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <motion.div
              key={service.href}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.7,
                delay: index * 0.1,
                ease: [0.21, 0.45, 0.27, 0.9]
              }}
              className="group h-full"
            >
              <Link 
                to={service.href}
                className="block h-full bg-white rounded-xl p-8 shadow-premium hover:shadow-premium-hover transition-all duration-500"
              >
                <div className="relative mb-6">
                  <div className="w-16 h-16 bg-coral-50 rounded-xl flex items-center justify-center group-hover:bg-coral-100 transition-transform duration-500 group-hover:scale-110">
                    <service.icon className="w-8 h-8 text-coral-500 transform group-hover:scale-110 transition-transform duration-500" />
                    <div className="absolute inset-0 bg-coral-200/20 rounded-xl blur-xl opacity-0 group-hover:opacity-100 transition-all duration-500" />
                  </div>
                </div>

                <h3 className="text-xl font-semibold mb-3 group-hover:text-coral-500 transition-colors duration-300">
                  {service.title}
                </h3>
                <p className="text-gray-600">
                  {service.description}
                </p>

                <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-coral-500/0 via-coral-500 to-coral-500/0 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-700" />
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};